/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
html {
  color: #000;
  background: #FFF; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%; }

legend {
  color: #000; }

.relux {
  /* LEGACY ICONS */ }
  .relux {
    position: relative;
    padding: 50px 0 0; }
    @media screen and (min-width: 1279px) {
      .relux {
        padding: 0; } }
  body.relux {
    padding-top: 50px;
    color: #000000; }
    @media screen and (min-width: 1279px) {
      body.relux {
        padding-top: 80px; } }
  .relux header.main-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 80000;
    background: rgba(255, 255, 255, 0);
    backface-visibility: hidden; }
    .relux header.main-header:after {
      content: '';
      display: table;
      clear: both; }
    @media screen and (min-width: 1279px) {
      .relux header.main-header {
        position: relative;
        height: auto;
        z-index: auto; }
        .relux header.main-header.fixed {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 80000; } }
    .relux header.main-header:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: #000;
      -webkit-transition: height 250ms ease-out;
      -moz-transition: height 250ms ease-out;
      -o-transition: height 250ms ease-out;
      transition: height 250ms ease-out; }
      @media screen and (min-width: 1279px) {
        .relux header.main-header:before {
          height: 80px; } }
    .relux header.main-header .header-wrapper {
      position: relative;
      box-sizing: border-box;
      width: 100%; }
      .relux header.main-header .header-wrapper div.container-fluid {
        padding-left: 0;
        padding-right: 0; }
        .relux header.main-header .header-wrapper div.container-fluid .navbar-collapse {
          padding-left: 20px;
          padding-right: 20px; }
      .relux header.main-header .header-wrapper h1 {
        position: absolute;
        display: inline-block;
        left: 20px;
        top: 0;
        margin: 0;
        font-size: 16px;
        line-height: 16px; }
        @media screen and (min-width: 2500px) {
          .relux header.main-header .header-wrapper h1 {
            left: 0; } }
        .relux header.main-header .header-wrapper h1 a {
          display: inline-block;
          vertical-align: top;
          width: 142.85714px;
          height: 24px;
          margin: 13px 0 0;
          background: transparent url(../assets/logo-white.svg) 0 0 no-repeat;
          background-size: auto 100%;
          text-indent: -9999px;
          -webkit-transition: margin 250ms ease-out;
          -moz-transition: margin 250ms ease-out;
          -o-transition: margin 250ms ease-out;
          transition: margin 250ms ease-out; }
          @media screen and (min-width: 1279px) {
            .relux header.main-header .header-wrapper h1 a {
              margin: 28px 0 0; } }
      .relux header.main-header .header-wrapper .drawer {
        position: fixed;
        box-sizing: border-box;
        top: 50px;
        left: 0;
        width: 100%;
        max-height: calc(100% - 70px);
        padding: 0 0 20px;
        font-size: 0;
        line-height: 0;
        text-align: center;
        background: #000;
        overflow: auto;
        z-index: -1;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-transition: -webkit-transform 250ms ease-out;
        -moz-transition: -moz-transform 250ms ease-out;
        -o-transition: -o-transform 250ms ease-out;
        transition: transform 250ms ease-out; }
        .relux header.main-header .header-wrapper .drawer.open {
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0); }
        @media screen and (min-width: 1279px) {
          .relux header.main-header .header-wrapper .drawer {
            position: relative;
            top: 0;
            width: auto;
            max-height: 0;
            padding: 0;
            text-align: left;
            background: none;
            overflow: visible;
            z-index: auto;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            -webkit-transition: -webkit-transform none;
            -moz-transition: -moz-transform none;
            -o-transition: -o-transform none;
            transition: transform none; }
            .relux header.main-header .header-wrapper .drawer:after {
              content: '';
              display: table;
              clear: both; } }
      .relux header.main-header .header-wrapper .drawer-button {
        display: none;
        position: absolute;
        top: 17px;
        right: 20px;
        width: 16px;
        height: 16px;
        z-index: 81000;
        color: #fff; }
        .relux header.main-header .header-wrapper .drawer-button.relicon-hamburger:before {
          position: relative;
          top: 2px; }
        .relux header.main-header .header-wrapper .drawer-button.relicon-close {
          color: #e2007a; }
        @media screen and (min-width: 1279px) {
          .relux header.main-header .header-wrapper .drawer-button {
            display: none; } }
    @media screen and (min-width: 1279px) {
      .relux header.main-header.narrow:before {
        height: 45px; }
      .relux header.main-header.narrow .header-wrapper h1 a {
        margin: 10.5px 0 0; } }
  .relux .grid {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 18.75px 28.125px 0;
    max-width: 100%; }
    @media screen and (min-width: 633.75px) {
      .relux .grid {
        margin: 18.75px 28.125px 0; } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid {
        margin: 37.5px 18.75px 0; } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid {
        margin: 56.25px 9.375px 0; } }
    @media screen and (min-width: 2535px) {
      .relux .grid {
        margin: 75px 0px 0; } }
    @media screen and (min-width: 2535px) {
      .relux .grid {
        margin: 75px auto 0;
        max-width: 2535px; } }
    .relux .grid [class^="grid-col-"], .relux .grid [class*=" grid-col-"] {
      width: 100%;
      margin: 0 9.375px 15px;
      box-sizing: border-box;
      border-collapse: collapse; }
      @media screen and (min-width: 633.75px) {
        .relux .grid [class^="grid-col-"], .relux .grid [class*=" grid-col-"] {
          margin: 0 9.375px 15px; } }
      @media screen and (min-width: 1267.5px) {
        .relux .grid [class^="grid-col-"], .relux .grid [class*=" grid-col-"] {
          margin: 0 18.75px 30px; } }
      @media screen and (min-width: 1901.25px) {
        .relux .grid [class^="grid-col-"], .relux .grid [class*=" grid-col-"] {
          margin: 0 28.125px 45px; } }
      @media screen and (min-width: 2535px) {
        .relux .grid [class^="grid-col-"], .relux .grid [class*=" grid-col-"] {
          margin: 0 37.5px 60px; } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-1 {
        width: calc(8.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-1 {
        width: calc(8.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-1 {
        width: calc(8.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-2 {
        width: calc(16.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-2 {
        width: calc(16.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-2 {
        width: calc(16.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-3 {
        width: calc(25% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-3 {
        width: calc(25% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-3 {
        width: calc(25% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-4 {
        width: calc(33.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-4 {
        width: calc(33.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-4 {
        width: calc(33.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-5 {
        width: calc(41.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-5 {
        width: calc(41.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-5 {
        width: calc(41.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-6 {
        width: calc(50% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-6 {
        width: calc(50% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-6 {
        width: calc(50% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-7 {
        width: calc(58.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-7 {
        width: calc(58.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-7 {
        width: calc(58.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-8 {
        width: calc(66.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-8 {
        width: calc(66.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-8 {
        width: calc(66.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-9 {
        width: calc(75% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-9 {
        width: calc(75% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-9 {
        width: calc(75% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-10 {
        width: calc(83.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-10 {
        width: calc(83.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-10 {
        width: calc(83.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-11 {
        width: calc(91.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-11 {
        width: calc(91.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-11 {
        width: calc(91.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .grid-col-12 {
        width: calc(100% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .grid-col-12 {
        width: calc(100% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .grid-col-12 {
        width: calc(100% - 75.2px); } }
    .relux .grid [class^="fw-grid-col-"], .relux .grid [class*=" fw-grid-col-"] {
      width: 100%;
      margin: 0 18.75px 15px 0;
      box-sizing: border-box;
      border-collapse: collapse; }
      @media screen and (min-width: 633.75px) {
        .relux .grid [class^="fw-grid-col-"], .relux .grid [class*=" fw-grid-col-"] {
          margin: 0 18.75px 15px 0; } }
      @media screen and (min-width: 1267.5px) {
        .relux .grid [class^="fw-grid-col-"], .relux .grid [class*=" fw-grid-col-"] {
          margin: 0 37.5px 30px 0; } }
      @media screen and (min-width: 1901.25px) {
        .relux .grid [class^="fw-grid-col-"], .relux .grid [class*=" fw-grid-col-"] {
          margin: 0 56.25px 45px 0; } }
      @media screen and (min-width: 2535px) {
        .relux .grid [class^="fw-grid-col-"], .relux .grid [class*=" fw-grid-col-"] {
          margin: 0 75px 60px 0; } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-1 {
        width: calc(8.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-1 {
        width: calc(8.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-1 {
        width: calc(8.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-2 {
        width: calc(16.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-2 {
        width: calc(16.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-2 {
        width: calc(16.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-3 {
        width: calc(25% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-3 {
        width: calc(25% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-3 {
        width: calc(25% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-4 {
        width: calc(33.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-4 {
        width: calc(33.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-4 {
        width: calc(33.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-5 {
        width: calc(41.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-5 {
        width: calc(41.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-5 {
        width: calc(41.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-6 {
        width: calc(50% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-6 {
        width: calc(50% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-6 {
        width: calc(50% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-7 {
        width: calc(58.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-7 {
        width: calc(58.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-7 {
        width: calc(58.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-8 {
        width: calc(66.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-8 {
        width: calc(66.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-8 {
        width: calc(66.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-9 {
        width: calc(75% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-9 {
        width: calc(75% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-9 {
        width: calc(75% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-10 {
        width: calc(83.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-10 {
        width: calc(83.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-10 {
        width: calc(83.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-11 {
        width: calc(91.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-11 {
        width: calc(91.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-11 {
        width: calc(91.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .fw-grid-col-12 {
        width: calc(100% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .fw-grid-col-12 {
        width: calc(100% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .fw-grid-col-12 {
        width: calc(100% - 75.2px); } }
    .relux .grid [class^="rw-grid-col-"], .relux .grid [class*=" rw-grid-col-"] {
      width: 100%;
      margin: 0 0 15px 18.75px;
      box-sizing: border-box;
      border-collapse: collapse; }
      @media screen and (min-width: 633.75px) {
        .relux .grid [class^="rw-grid-col-"], .relux .grid [class*=" rw-grid-col-"] {
          margin: 0 0 15px 18.75px; } }
      @media screen and (min-width: 1267.5px) {
        .relux .grid [class^="rw-grid-col-"], .relux .grid [class*=" rw-grid-col-"] {
          margin: 0 0 30px 37.5px; } }
      @media screen and (min-width: 1901.25px) {
        .relux .grid [class^="rw-grid-col-"], .relux .grid [class*=" rw-grid-col-"] {
          margin: 0 0 45px 56.25px; } }
      @media screen and (min-width: 2535px) {
        .relux .grid [class^="rw-grid-col-"], .relux .grid [class*=" rw-grid-col-"] {
          margin: 0 0 60px 75px; } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-1 {
        width: calc(8.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-1 {
        width: calc(8.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-1 {
        width: calc(8.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-2 {
        width: calc(16.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-2 {
        width: calc(16.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-2 {
        width: calc(16.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-3 {
        width: calc(25% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-3 {
        width: calc(25% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-3 {
        width: calc(25% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-4 {
        width: calc(33.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-4 {
        width: calc(33.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-4 {
        width: calc(33.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-5 {
        width: calc(41.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-5 {
        width: calc(41.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-5 {
        width: calc(41.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-6 {
        width: calc(50% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-6 {
        width: calc(50% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-6 {
        width: calc(50% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-7 {
        width: calc(58.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-7 {
        width: calc(58.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-7 {
        width: calc(58.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-8 {
        width: calc(66.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-8 {
        width: calc(66.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-8 {
        width: calc(66.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-9 {
        width: calc(75% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-9 {
        width: calc(75% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-9 {
        width: calc(75% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-10 {
        width: calc(83.33333% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-10 {
        width: calc(83.33333% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-10 {
        width: calc(83.33333% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-11 {
        width: calc(91.66667% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-11 {
        width: calc(91.66667% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-11 {
        width: calc(91.66667% - 75.2px); } }
    @media screen and (min-width: 1267.5px) {
      .relux .grid .rw-grid-col-12 {
        width: calc(100% - 37.7px); } }
    @media screen and (min-width: 1901.25px) {
      .relux .grid .rw-grid-col-12 {
        width: calc(100% - 56.45px); } }
    @media screen and (min-width: 2535px) {
      .relux .grid .rw-grid-col-12 {
        width: calc(100% - 75.2px); } }
  .relux .video-grid img {
    width: 97%;
    margin: 0 auto;
    display: block; }
  .relux .video-grid .video-title {
    text-align: center;
    font-weight: 600;
    margin-top: 1.0em; }
  .relux footer.main-footer {
    color: #fff;
    background: #000; }
    .relux footer.main-footer .footer-wrapper {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding: 30px 75px; }
      .relux footer.main-footer .footer-wrapper:after {
        content: '';
        display: table;
        clear: both; }
      @media screen and (min-width: 2500px) {
        .relux footer.main-footer .footer-wrapper {
          width: 2460px;
          margin: 0 auto;
          padding: 30px 0; } }
      @media screen and (min-width: 768px) {
        .relux footer.main-footer .footer-wrapper .col {
          float: left;
          width: 33.333%; }
          .relux footer.main-footer .footer-wrapper .col:nth-of-type(1) li {
            line-height: 25px; }
          .relux footer.main-footer .footer-wrapper .col:nth-of-type(4) {
            width: 100%;
            margin-top: 30px; } }
      .relux footer.main-footer .footer-wrapper nav ul li a {
        color: #fff;
        text-decoration: none; }
        .relux footer.main-footer .footer-wrapper nav ul li a:hover {
          color: #e2007a; }
      .relux footer.main-footer .footer-wrapper nav ul.social {
        font-size: 0;
        padding: 16px 0 0; }
        @media screen and (min-width: 768px) {
          .relux footer.main-footer .footer-wrapper nav ul.social {
            padding: 0; } }
        .relux footer.main-footer .footer-wrapper nav ul.social li {
          display: inline;
          margin: 0 22px 0 0; }
          @media screen and (min-width: 768px) {
            .relux footer.main-footer .footer-wrapper nav ul.social li {
              display: block;
              margin: 0 30px 0 0; } }
          .relux footer.main-footer .footer-wrapper nav ul.social li a {
            display: inline-block;
            line-height: 40px; }
            .relux footer.main-footer .footer-wrapper nav ul.social li a:before {
              vertical-align: top;
              font-size: 30px;
              line-height: 40px; }
            .relux footer.main-footer .footer-wrapper nav ul.social li a span {
              font-family: "Open Sans", sans-serif;
              font-weight: 300;
              margin: 0 0 0 22px;
              display: none; }
              @media screen and (min-width: 768px) {
                .relux footer.main-footer .footer-wrapper nav ul.social li a span {
                  display: inline; } }
            .relux footer.main-footer .footer-wrapper nav ul.social li a:hover {
              color: #fff; }
            .relux footer.main-footer .footer-wrapper nav ul.social li a:hover span {
              color: #e2007a; }
      .relux footer.main-footer .footer-wrapper h4 {
        text-transform: uppercase;
        padding: 16px 0; }
        @media screen and (min-width: 768px) {
          .relux footer.main-footer .footer-wrapper h4 {
            padding-top: 0; } }
      .relux footer.main-footer .footer-wrapper form p {
        position: relative; }
        @media screen and (min-width: 768px) {
          .relux footer.main-footer .footer-wrapper form p {
            padding-top: 16px; } }
        .relux footer.main-footer .footer-wrapper form p input[type="email"] {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border: 0;
          border-bottom: 2px solid #fff;
          color: #fff;
          background: none;
          padding: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
          .relux footer.main-footer .footer-wrapper form p input[type="email"]:focus {
            border-color: #e2007a; }
        .relux footer.main-footer .footer-wrapper form p button[type="submit"] {
          position: absolute;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: right;
          font-size: 12px;
          background: none;
          border: 0;
          color: #fff;
          outline: 0;
          padding: 0;
          cursor: pointer; }
          @media screen and (min-width: 768px) {
            .relux footer.main-footer .footer-wrapper form p button[type="submit"] {
              top: 16px; } }
          .relux footer.main-footer .footer-wrapper form p button[type="submit"]:focus {
            outline: 0; }
          .relux footer.main-footer .footer-wrapper form p button[type="submit"]:hover {
            color: #e2007a; }
  body {
    font-family: "Open Sans", sans-serif;
    font-weight: 300; }
  .relux h1,
  .relux .h1 {
    font-size: 48px;
    line-height: 84px; }
  .relux h2,
  .relux .h2 {
    font-size: 36px;
    line-height: 48px; }
  .relux h3,
  .relux .h3 {
    font-size: 24px;
    line-height: 32px; }
  .relux h4,
  .relux .h4 {
    font-size: 18px;
    line-height: 24px; }
  .relux .p-heading {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 1px; }
  .relux a {
    color: #e2007a;
    text-decoration: none; }
    .relux a:hover {
      text-decoration: underline; }
  .relux .text-center {
    text-align: center; }
  .relux .italic {
    font-style: italic; }
  .relux .semi-bold {
    font-weight: 400; }
  .relux .bold {
    font-weight: 600; }
  .relux .uppercase {
    text-transform: uppercase; }
  .relux p,
  .relux td {
    font-size: 14px;
    line-height: 22px;
    text-align: justify; }
  .relux ul, .relux ol {
    list-style: none; }
    .relux ul li, .relux ol li {
      list-style: none;
      font-size: 14px;
      line-height: 40px; }
  .relux .p-heading, .relux p, .relux ul, .relux ol {
    margin: 0 0 11px; }
  .relux .homepage-slider-title {
    font-size: 26px;
    line-height: 46px;
    margin-bottom: 14px; }
  .relux div.homepage-slider-text p {
    margin: 0 0 4px; }
  .relux .color-magenta {
    background-color: #e2007a; }
  .relux .text-color-magenta {
    color: #e2007a; }
  .relux .color-black {
    background-color: #000; }
  .relux .text-color-black {
    color: #000; }
  .relux .color-greyOne {
    background-color: #4d4d4d; }
  .relux .text-color-greyOne {
    color: #4d4d4d; }
  .relux .color-greyTwo {
    background-color: #666; }
  .relux .text-color-greyTwo {
    color: #666; }
  .relux .color-greyThree {
    background-color: #9b9b9b; }
  .relux .text-color-greyThree {
    color: #9b9b9b; }
  .relux .color-greyFour {
    background-color: #a4a4a4; }
  .relux .text-color-greyFour {
    color: #a4a4a4; }
  .relux .color-greyFive {
    background-color: #bfbfbf; }
  .relux .text-color-greyFive {
    color: #bfbfbf; }
  .relux .color-greySix {
    background-color: #dfdfdf; }
  .relux .text-color-greySix {
    color: #dfdfdf; }
  .relux .color-greySeven {
    background-color: #f2f2f2; }
  .relux .text-color-greySeven {
    color: #f2f2f2; }
  .relux .color-greyEight {
    background-color: #f7f7f7; }
  .relux .text-color-greyEight {
    color: #f7f7f7; }
  .relux .color-white {
    background-color: #fff; }
  .relux .text-color-white {
    color: #fff; }
  .relux label {
    display: inline-block;
    font-size: inherit;
    line-height: inherit; }
  .relux input[type="text"],
  .relux input[type="email"],
  .relux input[type="number"],
  .relux input[type="search"],
  .relux input[type="checkbox"],
  .relux input[type="radio"],
  .relux select,
  .relux textarea {
    -moz-appearance: none;
    -webkit-appearance: none; }
  .relux input[type="text"],
  .relux input[type="password"],
  .relux input[type="email"],
  .relux input[type="number"],
  .relux input[type="search"],
  .relux textarea,
  .relux select {
    width: 255px;
    height: 29px;
    font-size: 14px;
    line-height: 29px;
    padding: 0 10px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid rgba(191, 191, 191, 0.5);
    outline: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(191, 191, 191, 0.5);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(191, 191, 191, 0.5);
    box-shadow: inset 0 1px 3px 0 rgba(191, 191, 191, 0.5); }
    .relux input[type="text"]:focus,
    .relux input[type="password"]:focus,
    .relux input[type="email"]:focus,
    .relux input[type="number"]:focus,
    .relux input[type="search"]:focus,
    .relux textarea:focus,
    .relux select:focus {
      border-color: rgba(0, 0, 0, 0.5); }
    .relux input[type="text"].is-required,
    .relux input[type="password"].is-required,
    .relux input[type="email"].is-required,
    .relux input[type="number"].is-required,
    .relux input[type="search"].is-required,
    .relux textarea.is-required,
    .relux select.is-required {
      background: transparent url(../assets/required.svg) top 9px right 10px no-repeat;
      background-size: auto 11px; }
    .relux input[type="text"].validation-failed,
    .relux input[type="password"].validation-failed,
    .relux input[type="email"].validation-failed,
    .relux input[type="number"].validation-failed,
    .relux input[type="search"].validation-failed,
    .relux textarea.validation-failed,
    .relux select.validation-failed {
      color: #ff0060;
      border-color: rgba(255, 0, 96, 0.5); }
    .relux input[type="text"].big,
    .relux input[type="password"].big,
    .relux input[type="email"].big,
    .relux input[type="number"].big,
    .relux input[type="search"].big,
    .relux textarea.big,
    .relux select.big {
      width: 100%;
      height: 50px;
      font-size: 18px;
      line-height: 50px;
      padding: 0 30px; }
      .relux input[type="text"].big.is-required,
      .relux input[type="password"].big.is-required,
      .relux input[type="email"].big.is-required,
      .relux input[type="number"].big.is-required,
      .relux input[type="search"].big.is-required,
      .relux textarea.big.is-required,
      .relux select.big.is-required {
        background-position: top 19px right 30px; }
    .relux input[type="text"].full,
    .relux input[type="password"].full,
    .relux input[type="email"].full,
    .relux input[type="number"].full,
    .relux input[type="search"].full,
    .relux textarea.full,
    .relux select.full {
      width: 100%; }
  .relux input[type="search"]::-webkit-search-decoration, .relux input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }
  .relux textarea {
    height: initial; }
  .relux select {
    padding-right: 34px;
    line-height: 1em;
    background: #fff url(../assets/triangle-down.svg) top 10px right 7px no-repeat;
    background-size: 14px 9px; }
    .relux select:focus {
      background-image: url(../assets/triangle-up.svg); }
    .relux select[multiselect] {
      background-image: none; }
    .relux select::-ms-expand {
      display: none; }
  .relux option {
    line-height: 29px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 0; }
  .relux input[type="checkbox"],
  .relux input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .relux input[type="checkbox"]:focus, .relux input[type="checkbox"]:checked,
    .relux input[type="radio"]:focus,
    .relux input[type="radio"]:checked {
      outline: 0; }
    .relux input[type="checkbox"] + span,
    .relux input[type="radio"] + span {
      position: relative;
      display: inline-block;
      line-height: 22px; }
    .relux input[type="checkbox"] + span:before,
    .relux input[type="radio"] + span:before {
      content: '';
      position: relative;
      display: inline-block;
      vertical-align: top;
      left: 0;
      top: 3px;
      width: 14px;
      height: 14px;
      margin: 0 14px 0 0;
      box-sizing: border-box;
      background: #f2f2f2;
      border: 1px solid #4d4d4d;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px; }
    .relux input[type="checkbox"] + span:after,
    .relux input[type="radio"] + span:after {
      content: '';
      position: absolute;
      display: block;
      left: 3px;
      top: 6px;
      width: 8px;
      height: 8px;
      background: #e2007a;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      -webkit-transition: -webkit-transform 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      -moz-transition: -moz-transform 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      -o-transition: -o-transform 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      transition: transform 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px; }
    .relux input[type="checkbox"]:checked + span:after,
    .relux input[type="radio"]:checked + span:after {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
  .relux input[type="radio"] + span:before,
  .relux input[type="radio"] + span:after {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .relux .search {
    position: relative;
    display: inline-block; }
    .relux .search:after {
      content: "\e90a";
      position: absolute;
      top: 8px;
      right: 8px;
      width: 15px;
      height: 15px;
      font-family: "relux" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 15px;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #9b9b9b; }
    .relux .search.big:after {
      top: 18px;
      left: 36px;
      right: auto;
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #666; }
    .relux .search.big input[type="search"] {
      width: 403px;
      height: 60px;
      font-size: 18px;
      line-height: 50px;
      padding: 0 30px 0 90px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px; }
    .relux .search.full, .relux .search.autocomplete {
      width: 100%; }
      .relux .search.full input, .relux .search.autocomplete input {
        width: 100%; }
    .relux .search ul {
      margin: 0; }
    .relux .search.autocomplete {
      position: relative; }
      .relux .search.autocomplete .results {
        position: absolute;
        top: 100%;
        width: 100%;
        background: #fff;
        border: 1px solid rgba(191, 191, 191, 0.5);
        border-top: 0;
        box-sizing: border-box;
        display: none;
        z-index: 1;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
        .relux .search.autocomplete .results.open {
          display: block; }
        .relux .search.autocomplete .results ul {
          padding: 0;
          font-size: 0;
          line-height: 0; }
          .relux .search.autocomplete .results ul li {
            display: block;
            font-size: 14px;
            line-height: 28px;
            padding: 0 10px;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            .relux .search.autocomplete .results ul li:hover {
              background: #f2f2f2;
              text-decoration: none; }
  .relux .button {
    display: inline-block;
    vertical-align: top;
    height: 37px;
    line-height: 35px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0 10px;
    color: #e2007a;
    border: 1px solid #e2007a;
    background: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer; }
    .relux .button:focus {
      outline: 0; }
    .relux .button:not(.disabled):hover {
      text-decoration: none;
      color: #fff;
      background: #e2007a; }
    .relux .button.js-watchlist-add, .relux .button.js-datasheet-link, .relux .button.js-rolfz-link {
      color: #fff;
      border-color: #fff;
      font-weight: 600; }
      .relux .button.js-watchlist-add:not(.disabled):hover, .relux .button.js-datasheet-link:not(.disabled):hover, .relux .button.js-rolfz-link:not(.disabled):hover {
        color: white;
        background: #e2007a;
        border-color: #e2007a; }
    .relux .button.white {
      color: #fff;
      border-color: #fff; }
      .relux .button.white:not(.disabled):hover {
        color: #000;
        background: #fff; }
    .relux .button.disabled {
      color: #9b9b9b;
      border-color: #a4a4a4;
      cursor: default; }
  .relux input[type="submit"].button {
    -webkit-appearance: none; }
  .relux .homepage-slider-button {
    margin-top: 27px; }

@keyframes mitglied_animation {
  from {
    background-color: #E6007E; }
  to {
    background-color: #888888; } }
  .relux .mitglied_button {
    background-color: #E6007E;
    border-radius: 35px 35px 35px 35px;
    border: none;
    color: white;
    height: 44px;
    padding: 12px 18px 12px 15px;
    text-align: left;
    cursor: pointer;
    cursor: hand; }
  .relux .mitglied_button:hover {
    background-color: #888888;
    animation-name: mitglied_animation;
    animation-duration: 0.25s; }
  .relux .mitglied_button_text {
    font-size: 18px;
    font-weight: 600;
    color: white;
    width: auto;
    display: inline;
    margin-left: 10px; }
  .relux .mitglied_logo {
    height: 45px;
    width: 45px;
    float: left;
    margin-top: -12px; }
  .relux .pill {
    display: inline-block;
    vertical-align: top;
    height: 31px;
    line-height: 29px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 0 8px;
    color: #fff;
    background: #9b9b9b;
    border: 1px solid #9b9b9b;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    cursor: pointer; }
    .relux .pill:hover {
      text-decoration: none;
      color: #a4a4a4;
      background: transparent; }
    .relux .pill.active {
      background: #e2007a;
      border-color: #e2007a; }
      .relux .pill.active:hover {
        color: #e2007a;
        background: transparent; }
  .relux input[type="submit"].pill {
    -webkit-appearance: none; }
  .relux .filter-pills {
    list-style: none;
    padding: 0;
    margin: 0 0 18px;
    font-size: 0; }
    .relux .filter-pills li {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline; }
    .relux .filter-pills .label {
      display: inline-block;
      vertical-align: top;
      line-height: 31px;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 1px;
      margin: 0 10px 12px 0; }
    .relux .filter-pills .pill {
      margin: 0 10px 12px 0; }
    .relux .filter-pills .slimpill {
      vertical-align: middle;
      height: 23px;
      line-height: 21px;
      padding: 0 6px; }
    .relux .filter-pills.text-center .label {
      margin-right: 5px; }
    .relux .filter-pills.text-center .pill {
      margin-left: 5px;
      margin-right: 5px; }
  .relux .expandable {
    display: inline-block;
    width: 100%;
    background: #f2f2f2;
    text-align: left; }
    .relux .expandable:after {
      content: '';
      display: table;
      clear: both; }
    .relux .expandable dt {
      position: relative;
      display: table;
      width: 100%;
      min-height: 45px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .relux .expandable dt:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0;
        height: 2px;
        background: rgba(191, 191, 191, 0.5);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: width 450ms ease-out;
        -moz-transition: width 450ms ease-out;
        -o-transition: width 450ms ease-out;
        transition: width 450ms ease-out; }
      .relux .expandable dt span {
        position: relative;
        display: table-cell;
        padding: 4.5px 44px 4.5px 18px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        vertical-align: middle;
        word-break: break-word; }
      .relux .expandable dt:after {
        content: "\e90e";
        position: absolute;
        top: 50%;
        right: 16px;
        width: 14px;
        height: 14px;
        font-family: "relux" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 14px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #e2007a;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
    .relux .expandable dd {
      padding: 0 20px;
      line-height: 34px;
      /* display: none; */
      cursor: pointer;
      font-size: 0.9em; }
      .relux .expandable dd:hover {
        background: rgba(191, 191, 191, 0.5); }
      .relux .expandable dd:first-of-type {
        margin-top: 5px; }
      .relux .expandable dd:last-of-type {
        margin-bottom: 5px; }
      .relux .expandable dd label {
        display: block;
        line-height: inherit;
        font-size: 14px; }
      .relux .expandable dd .selected.active {
        display: block; }
    .relux .expandable.ldc:after {
      content: '';
      height: 4px;
      width: 100%;
      display: none; }
    .relux .expandable.ldc dd[class^="ldc-"], .relux .expandable.ldc dd[class*=" ldc-"] {
      vertical-align: top;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 4px 0 0 4px;
      cursor: pointer;
      background: transparent url(../assets/rlx_sprite.png) 0 0 no-repeat; }
    .relux .expandable.ldc dd.ldc-12001 {
      background-position: -0px -40px; }
      .relux .expandable.ldc dd.ldc-12001:hover {
        background-position: -0px -80px; }
      .relux .expandable.ldc dd.ldc-12001.active {
        background-position: -0px 0; }
    .relux .expandable.ldc dd.ldc-12002 {
      background-position: -40px -40px; }
      .relux .expandable.ldc dd.ldc-12002:hover {
        background-position: -40px -80px; }
      .relux .expandable.ldc dd.ldc-12002.active {
        background-position: -40px 0; }
    .relux .expandable.ldc dd.ldc-12003 {
      background-position: -80px -40px; }
      .relux .expandable.ldc dd.ldc-12003:hover {
        background-position: -80px -80px; }
      .relux .expandable.ldc dd.ldc-12003.active {
        background-position: -80px 0; }
    .relux .expandable.ldc dd.ldc-12004 {
      background-position: -120px -40px; }
      .relux .expandable.ldc dd.ldc-12004:hover {
        background-position: -120px -80px; }
      .relux .expandable.ldc dd.ldc-12004.active {
        background-position: -120px 0; }
    .relux .expandable.ldc dd.ldc-12005 {
      background-position: -160px -40px; }
      .relux .expandable.ldc dd.ldc-12005:hover {
        background-position: -160px -80px; }
      .relux .expandable.ldc dd.ldc-12005.active {
        background-position: -160px 0; }
    .relux .expandable.ldc dd.ldc-12006 {
      background-position: -200px -40px; }
      .relux .expandable.ldc dd.ldc-12006:hover {
        background-position: -200px -80px; }
      .relux .expandable.ldc dd.ldc-12006.active {
        background-position: -200px 0; }
    .relux .expandable.ldc dd.ldc-12007 {
      background-position: -240px -40px; }
      .relux .expandable.ldc dd.ldc-12007:hover {
        background-position: -240px -80px; }
      .relux .expandable.ldc dd.ldc-12007.active {
        background-position: -240px 0; }
    .relux .expandable.ldc dd.ldc-12008 {
      background-position: -280px -40px; }
      .relux .expandable.ldc dd.ldc-12008:hover {
        background-position: -280px -80px; }
      .relux .expandable.ldc dd.ldc-12008.active {
        background-position: -280px 0; }
    .relux .expandable.ldc dd.ldc-12009 {
      background-position: -320px -40px; }
      .relux .expandable.ldc dd.ldc-12009:hover {
        background-position: -320px -80px; }
      .relux .expandable.ldc dd.ldc-12009.active {
        background-position: -320px 0; }
    .relux .expandable.ldc dd.ldc-12010 {
      background-position: -360px -40px; }
      .relux .expandable.ldc dd.ldc-12010:hover {
        background-position: -360px -80px; }
      .relux .expandable.ldc dd.ldc-12010.active {
        background-position: -360px 0; }
    .relux .expandable.ldc dd.ldc-12011 {
      background-position: -400px -40px; }
      .relux .expandable.ldc dd.ldc-12011:hover {
        background-position: -400px -80px; }
      .relux .expandable.ldc dd.ldc-12011.active {
        background-position: -400px 0; }
    .relux .expandable.ldc dd.ldc-12012 {
      background-position: -440px -40px; }
      .relux .expandable.ldc dd.ldc-12012:hover {
        background-position: -440px -80px; }
      .relux .expandable.ldc dd.ldc-12012.active {
        background-position: -440px 0; }
    .relux .expandable.ldc dd.ldc-12013 {
      background-position: -480px -40px; }
      .relux .expandable.ldc dd.ldc-12013:hover {
        background-position: -480px -80px; }
      .relux .expandable.ldc dd.ldc-12013.active {
        background-position: -480px 0; }
    .relux .expandable.ldc dd.ldc-12014 {
      background-position: -520px -40px; }
      .relux .expandable.ldc dd.ldc-12014:hover {
        background-position: -520px -80px; }
      .relux .expandable.ldc dd.ldc-12014.active {
        background-position: -520px 0; }
    .relux .expandable.ldc dd.ldc-12015 {
      background-position: -560px -40px; }
      .relux .expandable.ldc dd.ldc-12015:hover {
        background-position: -560px -80px; }
      .relux .expandable.ldc dd.ldc-12015.active {
        background-position: -560px 0; }
    .relux .expandable.ldc dd.ldc-12001 {
      display: none !important; }
    .relux .expandable:hover dt:before, .relux .expandable.open dt:before {
      width: 100%; }
    .relux .expandable.open dt:hover {
      background: rgba(191, 191, 191, 0.5); }
    .relux .expandable.open dt:after {
      content: "\e90b"; }
    .relux .expandable.open dd {
      display: block; }
    .relux .expandable.open.ldc dd {
      display: inline-block; }
    .relux .expandable.open.ldc:after {
      display: block; }
    .relux .expandable dd.sensor-characteristic-filter:after {
      content: '';
      height: 4px;
      width: 100%;
      display: none; }
    .relux .expandable dd.sensor-characteristic-filter[class^="sensor-characteristic-"], .relux .expandable dd.sensor-characteristic-filter[class*=" sensor-characteristic-"] {
      float: left;
      vertical-align: top;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 4px 0 0 4px;
      cursor: pointer;
      background: transparent url(../assets/rlx_sprite.png) 0 0 no-repeat; }
    .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40101 {
      background-position: -0px -280px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40101:hover {
        background-position: -0px -320px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40101.active {
        background-position: -0px -240px; }
    .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40102 {
      background-position: -40px -280px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40102:hover {
        background-position: -40px -320px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40102.active {
        background-position: -40px -240px; }
    .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40103 {
      background-position: -80px -280px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40103:hover {
        background-position: -80px -320px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40103.active {
        background-position: -80px -240px; }
    .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40104 {
      background-position: -120px -280px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40104:hover {
        background-position: -120px -320px; }
      .relux .expandable dd.sensor-characteristic-filter.sensor-characteristic-40104.active {
        background-position: -120px -240px; }
    .relux .expandable dd.lamp-shape-filter:after {
      content: '';
      height: 4px;
      width: 100%;
      display: none; }
    .relux .expandable dd.lamp-shape-filter[class^="lamp-shape-"], .relux .expandable dd.lamp-shape-filter[class*=" lamp-shape-"] {
      float: left;
      vertical-align: top;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 4px 0 0 4px;
      cursor: pointer;
      background: transparent url(../assets/rlx_sprite.png) 0 0 no-repeat; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22101 {
      background-position: -0px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22101:hover {
        background-position: -0px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22101.active {
        background-position: -0px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22102 {
      background-position: -40px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22102:hover {
        background-position: -40px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22102.active {
        background-position: -40px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22103 {
      background-position: -80px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22103:hover {
        background-position: -80px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22103.active {
        background-position: -80px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22104 {
      background-position: -120px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22104:hover {
        background-position: -120px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22104.active {
        background-position: -120px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22105 {
      background-position: -160px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22105:hover {
        background-position: -160px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22105.active {
        background-position: -160px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22106 {
      background-position: -200px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22106:hover {
        background-position: -200px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22106.active {
        background-position: -200px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22107 {
      background-position: -240px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22107:hover {
        background-position: -240px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22107.active {
        background-position: -240px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22108 {
      background-position: -280px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22108:hover {
        background-position: -280px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22108.active {
        background-position: -280px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22109 {
      background-position: -320px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22109:hover {
        background-position: -320px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22109.active {
        background-position: -320px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22110 {
      background-position: -360px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22110:hover {
        background-position: -360px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22110.active {
        background-position: -360px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22111 {
      background-position: -400px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22111:hover {
        background-position: -400px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22111.active {
        background-position: -400px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22112 {
      background-position: -440px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22112:hover {
        background-position: -440px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22112.active {
        background-position: -440px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22113 {
      background-position: -480px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22113:hover {
        background-position: -480px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22113.active {
        background-position: -480px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22114 {
      background-position: -520px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22114:hover {
        background-position: -520px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22114.active {
        background-position: -520px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22115 {
      background-position: -560px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22115:hover {
        background-position: -560px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22115.active {
        background-position: -560px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22116 {
      background-position: -600px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22116:hover {
        background-position: -600px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22116.active {
        background-position: -600px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22117 {
      background-position: -640px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22117:hover {
        background-position: -640px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22117.active {
        background-position: -640px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22118 {
      background-position: -680px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22118:hover {
        background-position: -680px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22118.active {
        background-position: -680px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22119 {
      background-position: -720px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22119:hover {
        background-position: -720px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22119.active {
        background-position: -720px -120px; }
    .relux .expandable dd.lamp-shape-filter.lamp-shape-22120 {
      background-position: -760px -160px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22120:hover {
        background-position: -760px -200px; }
      .relux .expandable dd.lamp-shape-filter.lamp-shape-22120.active {
        background-position: -760px -120px; }
  .relux .ldc-12001 {
    display: none; }
  .relux .ldc-12002 {
    background-position: -40px -40px; }
  .relux .ldc-12002:hover, .relux .ldc-12002.active:active {
    background-position: -40px -80px; }
  .relux .ldc-12002:active, .relux .ldc-12002.active, .relux .ldc-12002.active:hover {
    background-position: -40px 0; }
  .relux .ldc-12003 {
    background-position: -80px -40px; }
  .relux .ldc-12003:hover, .relux .ldc-12003.active:active {
    background-position: -80px -80px; }
  .relux .ldc-12003:active, .relux .ldc-12003.active, .relux .ldc-12003.active:hover {
    background-position: -80px 0; }
  .relux .ldc-12004 {
    background-position: -120px -40px; }
  .relux .ldc-12004:hover, .relux .ldc-12004.active:active {
    background-position: -120px -80px; }
  .relux .ldc-12004:active, .relux .ldc-12004.active, .relux .ldc-12004.active:hover {
    background-position: -120px 0; }
  .relux .ldc-12005 {
    background-position: -160px -40px; }
  .relux .ldc-12005:hover, .relux .ldc-12005.active:active {
    background-position: -160px -80px; }
  .relux .ldc-12005:active, .relux .ldc-12005.active, .relux .ldc-12005.active:hover {
    background-position: -160px 0; }
  .relux .ldc-12006 {
    background-position: -200px -40px; }
  .relux .ldc-12006:hover, .relux .ldc-12006.active:active {
    background-position: -200px -80px; }
  .relux .ldc-12006:active, .relux .ldc-12006.active, .relux .ldc-12006.active:hover {
    background-position: -200px 0; }
  .relux .ldc-12007 {
    background-position: -240px -40px; }
  .relux .ldc-12007:hover, .relux .ldc-12007.active:active {
    background-position: -240px -80px; }
  .relux .ldc-12007:active, .relux .ldc-12007.active, .relux .ldc-12007.active:hover {
    background-position: -240px 0; }
  .relux .ldc-12008 {
    background-position: -280px -40px; }
  .relux .ldc-12008:hover, .relux .ldc-12008.active:active {
    background-position: -280px -80px; }
  .relux .ldc-12008:active, .relux .ldc-12008.active, .relux .ldc-12008.active:hover {
    background-position: -280px 0; }
  .relux .ldc-12009 {
    background-position: -320px -40px; }
  .relux .ldc-12009:hover, .relux .ldc-12009.active:active {
    background-position: -320px -80px; }
  .relux .ldc-12009:active, .relux .ldc-12009.active, .relux .ldc-12009.active:hover {
    background-position: -320px 0; }
  .relux .ldc-12010 {
    background-position: -360px -40px; }
  .relux .ldc-12010:hover, .relux .ldc-12010.active:active {
    background-position: -360px -80px; }
  .relux .ldc-12010:active, .relux .ldc-12010.active, .relux .ldc-12010.active:hover {
    background-position: -360px 0; }
  .relux .ldc-12011 {
    background-position: -400px -40px; }
  .relux .ldc-12011:hover, .relux .ldc-12011.active:active {
    background-position: -400px -80px; }
  .relux .ldc-12011:active, .relux .ldc-12011.active, .relux .ldc-12011.active:hover {
    background-position: -400px 0; }
  .relux .ldc-12012 {
    background-position: -440px -40px; }
  .relux .ldc-12012:hover, .relux .ldc-12012.active:active {
    background-position: -440px -80px; }
  .relux .ldc-12012:active, .relux .ldc-12012.active, .relux .ldc-12012.active:hover {
    background-position: -440px 0; }
  .relux .ldc-12013 {
    background-position: -480px -40px; }
  .relux .ldc-12013:hover, .relux .ldc-12013.active:active {
    background-position: -480px -80px; }
  .relux .ldc-12013:active, .relux .ldc-12013.active, .relux .ldc-12013.active:hover {
    background-position: -480px 0; }
  .relux .ldc-12014 {
    background-position: -520px -40px; }
  .relux .ldc-12014:hover, .relux .ldc-12014.active:active {
    background-position: -520px -80px; }
  .relux .ldc-12014:active, .relux .ldc-12014.active, .relux .ldc-12014.active:hover {
    background-position: -520px 0; }
  .relux .ldc-12015 {
    background-position: -560px -40px; }
  .relux .ldc-12015:hover, .relux .ldc-12015.active:active {
    background-position: -560px -80px; }
  .relux .ldc-12015:active, .relux .ldc-12015.active, .relux .ldc-12015.active:hover {
    background-position: -560px 0; }
  .relux .switcher-toggle {
    position: relative;
    display: inline-block;
    height: 18px;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    border: 1px solid #bfbfbf;
    background: #fff;
    overflow: hidden;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px; }
    .relux .switcher-toggle li {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0 30px 0 0;
      font-size: 0;
      line-height: 0;
      display: inline; }
      .relux .switcher-toggle li:last-of-type {
        margin-right: 0; }
      .relux .switcher-toggle li.switcher-toggle-highlight {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0;
        margin: 0;
        background: #9b9b9b;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        -webkit-transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        -moz-transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        -o-transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28); }
      .relux .switcher-toggle li a {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #9b9b9b;
        text-decoration: none;
        display: inline-block;
        padding: 0 30px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px; }
        .relux .switcher-toggle li a:hover {
          text-decoration: none;
          color: #000; }
        .relux .switcher-toggle li a.active {
          color: #fff; }
      @media screen and (max-width: 767px) {
        .relux .switcher-toggle li {
          margin: 0 2px 0 0; }
          .relux .switcher-toggle li a {
            padding: 0 2px;
            font-size: 10px; } }
    .relux .switcher-toggle:not(.switcher-toggle-built) > li:first-of-type > a {
      color: #fff;
      background: #9b9b9b; }
  .relux .switcher {
    position: relative;
    background: #f2f2f2;
    text-align: center; }
    .relux .switcher .switcher-toggle {
      margin: 45px 0 20px; }
    .relux .switcher .switcher-wrapper {
      position: relative;
      overflow: hidden;
      padding: 0 0 20px;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d; }
      .relux .switcher .switcher-wrapper .switcher-slider {
        position: relative;
        backface-visibility: hidden;
        white-space: nowrap;
        -webkit-transition: -webkit-transform 250ms ease-in-out 50ms;
        -moz-transition: -moz-transform 250ms ease-in-out 50ms;
        -o-transition: -o-transform 250ms ease-in-out 50ms;
        transition: transform 250ms ease-in-out 50ms; }
        .relux .switcher .switcher-wrapper .switcher-slider .switcher-panel {
          display: inline-block;
          vertical-align: top;
          white-space: normal; }
    .relux .switcher .switcher-panel {
      position: relative;
      text-align: initial; }
  .relux .datasheet-table,
  .relux .table,
  .relux .family-table {
    background: #f2f2f2; }
    .relux .datasheet-table > h1,
    .relux .datasheet-table > h2,
    .relux .datasheet-table > h3,
    .relux .datasheet-table > h4,
    .relux .datasheet-table > h5,
    .relux .datasheet-table > h6,
    .relux .table > h1,
    .relux .table > h2,
    .relux .table > h3,
    .relux .table > h4,
    .relux .table > h5,
    .relux .table > h6,
    .relux .family-table > h1,
    .relux .family-table > h2,
    .relux .family-table > h3,
    .relux .family-table > h4,
    .relux .family-table > h5,
    .relux .family-table > h6 {
      font-size: 24px;
      font-weight: 300;
      line-height: 90px;
      letter-spacing: 1px;
      padding: 0 37.5px;
      background: #f2f2f2; }
      @media screen and (min-width: 2535px) {
        .relux .datasheet-table > h1,
        .relux .datasheet-table > h2,
        .relux .datasheet-table > h3,
        .relux .datasheet-table > h4,
        .relux .datasheet-table > h5,
        .relux .datasheet-table > h6,
        .relux .table > h1,
        .relux .table > h2,
        .relux .table > h3,
        .relux .table > h4,
        .relux .table > h5,
        .relux .table > h6,
        .relux .family-table > h1,
        .relux .family-table > h2,
        .relux .family-table > h3,
        .relux .family-table > h4,
        .relux .family-table > h5,
        .relux .family-table > h6 {
          position: relative;
          max-width: 2460px;
          margin: 0 auto;
          padding: 0; } }
    .relux .datasheet-table.family-table-articles,
    .relux .table.family-table-articles,
    .relux .family-table.family-table-articles {
      max-height: 500px;
      overflow-y: scroll; }
  .relux table {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    margin: 0 0 15px; }
    @media screen and (min-width: 633.75px) {
      .relux table {
        margin: 0 0 15px; } }
    @media screen and (min-width: 1267.5px) {
      .relux table {
        margin: 0 0 30px; } }
    @media screen and (min-width: 1901.25px) {
      .relux table {
        margin: 0 0 45px; } }
    @media screen and (min-width: 2535px) {
      .relux table {
        margin: 0 0 60px; } }
    .relux table thead tr {
      background: #4d4d4d; }
      .relux table thead tr th {
        text-transform: uppercase;
        color: #fff; }
    .relux table tr td,
    .relux table tr th {
      padding: 6px 75px 6px 0; }
      .relux table tr td:first-child,
      .relux table tr th:first-child {
        padding-left: 37.5px; }
      .relux table tr td:last-child,
      .relux table tr th:last-child {
        padding-right: 37.5px; }
      @media screen and (min-width: 2535px) {
        .relux table tr td:first-child,
        .relux table tr th:first-child {
          padding-left: calc(2% + 185px); }
        .relux table tr td:last-child,
        .relux table tr th:last-child {
          padding-right: calc(2% + 185px); } }
    .relux table tr:nth-child(odd) td {
      background: #fff; }
  .relux .table-grid {
    background: #f7f7f7; }
    .relux .table-grid > h1,
    .relux .table-grid > h2,
    .relux .table-grid > h3,
    .relux .table-grid > h4,
    .relux .table-grid > h5,
    .relux .table-grid > h6 {
      font-size: 24px;
      font-weight: 300;
      line-height: 90px;
      letter-spacing: 1px;
      padding: 0 37.5px; }
      @media screen and (min-width: 2535px) {
        .relux .table-grid > h1,
        .relux .table-grid > h2,
        .relux .table-grid > h3,
        .relux .table-grid > h4,
        .relux .table-grid > h5,
        .relux .table-grid > h6 {
          position: relative;
          max-width: 2460px;
          margin: 0 auto;
          padding: 0; } }
    .relux .table-grid ul {
      margin: 0 37.5px;
      padding: 0 0 60px;
      list-style: none;
      font-size: 0;
      line-height: 0; }
      @media screen and (min-width: 2535px) {
        .relux .table-grid ul {
          position: relative;
          max-width: 2460px;
          margin: 0 auto; } }
      .relux .table-grid ul li {
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 20px) / 2);
        margin: 0 20px 18px 0;
        padding: 20px;
        box-sizing: border-box;
        list-style: none; }
        .relux .table-grid ul li:nth-of-type(2n) {
          margin-right: 0; }
        @media screen and (min-width: 1690px) {
          .relux .table-grid ul li {
            width: calc((100% - 60px) / 4); }
            .relux .table-grid ul li:nth-of-type(2n) {
              margin-right: 20px; }
            .relux .table-grid ul li:nth-of-type(4n) {
              margin-right: 0; } }
        @media screen and (min-width: 2535px) {
          .relux .table-grid ul li {
            width: calc((100% - 100px) / 6); }
            .relux .table-grid ul li:nth-of-type(4n) {
              margin-right: 20px; }
            .relux .table-grid ul li:nth-of-type(6n) {
              margin-right: 0; } }
    .relux .table-grid.logos ul li {
      border: 1px solid #dfdfdf;
      font-size: 0;
      line-height: 0; }
      .relux .table-grid.logos ul li a img {
        position: relative;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto; }
  .relux .table-results-horizontal {
    margin: 0 0 15px;
    font-size: 0;
    line-height: 0;
    overflow-x: hidden; }
    .relux .table-results-horizontal ul {
      position: relative;
      display: block;
      padding: 0;
      margin: 0;
      font-size: 0;
      line-height: 0;
      white-space: nowrap; }
      .relux .table-results-horizontal ul li {
        display: inline;
        font-size: 0;
        line-height: 0;
        margin: 0 20px 0 0; }
        .relux .table-results-horizontal ul li a {
          position: relative;
          display: inline-block;
          width: 140px;
          height: 140px; }
          .relux .table-results-horizontal ul li a img {
            position: relative;
            width: 100%;
            height: auto;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0); }
          .relux .table-results-horizontal ul li a > span {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 30px;
            color: #fff;
            white-space: normal;
            background: rgba(0, 0, 0, 0.8); }
          @media screen and (min-width: 768px) {
            .relux .table-results-horizontal ul li a {
              overflow: hidden; }
              .relux .table-results-horizontal ul li a > span {
                display: block;
                -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -ms-transform: translateY(100%);
                -o-transform: translateY(100%);
                transform: translateY(100%);
                -webkit-transition: -webkit-transform 250ms ease-out;
                -moz-transition: -moz-transform 250ms ease-out;
                -o-transition: -o-transform 250ms ease-out;
                transition: transform 250ms ease-out; }
              .relux .table-results-horizontal ul li a:hover > span {
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0); } }
        .relux .table-results-horizontal ul li:first-of-type a {
          width: 282px;
          background: #f2f2f2; }
          .relux .table-results-horizontal ul li:first-of-type a img {
            width: 80px;
            margin: 30px; }
          .relux .table-results-horizontal ul li:first-of-type a .info {
            position: absolute;
            left: 140px;
            top: 0;
            width: 140px;
            height: 140px;
            padding: 20px;
            box-sizing: border-box;
            border-left: 2px solid rgba(191, 191, 191, 0.5); }
            .relux .table-results-horizontal ul li:first-of-type a .info span,
            .relux .table-results-horizontal ul li:first-of-type a .info strong {
              font-size: 14px;
              letter-spacing: 1px;
              line-height: 33.33333px; }
            .relux .table-results-horizontal ul li:first-of-type a .info strong {
              font-weight: 300; }
              .relux .table-results-horizontal ul li:first-of-type a .info strong:after {
                position: relative;
                display: inline-block;
                content: '\e903';
                font-family: "relux" !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-align: center;
                text-transform: none;
                font-size: 14px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: #666; }
          .relux .table-results-horizontal ul li:first-of-type a:hover {
            -webkit-transition: background-color 250ms ease-out;
            -moz-transition: background-color 250ms ease-out;
            -o-transition: background-color 250ms ease-out;
            transition: background-color 250ms ease-out;
            background: rgba(191, 191, 191, 0.5); }
        .relux .table-results-horizontal ul li:last-of-type {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          display: none; }
          @media screen and (min-width: 768px) {
            .relux .table-results-horizontal ul li:last-of-type {
              display: block; } }
          .relux .table-results-horizontal ul li:last-of-type a {
            width: 70px;
            background: #f2f2f2;
            opacity: .8;
            font-size: 0;
            -webkit-transition: opacity 250ms ease-out;
            -moz-transition: opacity 250ms ease-out;
            -o-transition: opacity 250ms ease-out;
            transition: opacity 250ms ease-out; }
            .relux .table-results-horizontal ul li:last-of-type a:hover {
              opacity: 1;
              text-decoration: none; }
            .relux .table-results-horizontal ul li:last-of-type a:after {
              position: relative;
              display: inline-block;
              content: '\e905';
              right: 0;
              width: 100%;
              height: 80px;
              margin: 30px 0;
              font-family: "relux" !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-align: center;
              text-transform: none;
              font-size: 80px;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: #000; }
  .relux .table-results-thumbs ul {
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 415px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 20px)/2); }
        .relux .table-results-thumbs ul li:nth-child(2n) {
          margin-right: 0; } }
    @media screen and (min-width: 416px) and (max-width: 1024px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 40px)/3); }
        .relux .table-results-thumbs ul li:nth-child(3n) {
          margin-right: 0; } }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 60px)/4); }
        .relux .table-results-thumbs ul li:nth-child(4n) {
          margin-right: 0; } }
    @media screen and (min-width: 1280px) and (max-width: 1600px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 80px)/5); }
        .relux .table-results-thumbs ul li:nth-child(5n) {
          margin-right: 0; } }
    @media screen and (min-width: 1600px) and (max-width: 1920px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 100px)/6); }
        .relux .table-results-thumbs ul li:nth-child(6n) {
          margin-right: 0; } }
    @media screen and (min-width: 1920px) and (max-width: 2560px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 120px)/7); }
        .relux .table-results-thumbs ul li:nth-child(7n) {
          margin-right: 0; } }
    @media screen and (min-width: 2560px) {
      .relux .table-results-thumbs ul li {
        width: calc((100% - 160px)/9); }
        .relux .table-results-thumbs ul li:nth-child(9n) {
          margin-right: 0; } }
    .relux .table-results-thumbs ul:after {
      content: '';
      display: table;
      clear: both; }
    .relux .table-results-thumbs ul li {
      display: inline-block;
      float: left;
      margin: 0 20px 20px 0;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d; }
      .relux .table-results-thumbs ul li:before {
        content: '';
        display: block;
        padding-top: 100%; }
      .relux .table-results-thumbs ul li a {
        display: block;
        width: 100%;
        height: 100%;
        background: #f2f2f2;
        overflow: hidden; }
        .relux .table-results-thumbs ul li a img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: auto;
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
          -ms-transform: translateZ(0);
          -o-transform: translateZ(0);
          transform: translateZ(0); }
        .relux .table-results-thumbs ul li a > span {
          position: absolute;
          display: block;
          width: 100%;
          left: 0;
          bottom: 0;
          padding: 0 10px;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 30px;
          color: #fff;
          white-space: normal;
          background: rgba(0, 0, 0, 0.8); }
  .relux nav.breadcrumb {
    background: #f2f2f2; }
    .relux nav.breadcrumb ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0;
      white-space: nowrap; }
      .relux nav.breadcrumb ul li {
        position: relative;
        display: inline-block;
        list-style: none;
        padding: 0;
        border-right: 10px solid #fff; }
        .relux nav.breadcrumb ul li:before, .relux nav.breadcrumb ul li:after {
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          content: '';
          display: block; }
        .relux nav.breadcrumb ul li:before {
          left: 0;
          border-top: 23px solid transparent;
          border-bottom: 23px solid transparent;
          border-left: 16px solid #fff; }
        .relux nav.breadcrumb ul li:after {
          left: -10px;
          border-top: 23px solid transparent;
          border-bottom: 23px solid transparent;
          border-left: 16px solid #f2f2f2; }
        .relux nav.breadcrumb ul li > * {
          display: inline-block;
          padding: 0 15px 0 35px;
          font-size: 14px;
          line-height: 46px;
          text-decoration: none;
          color: #000; }
        .relux nav.breadcrumb ul li a:hover {
          text-decoration: underline; }
        .relux nav.breadcrumb ul li:first-of-type:before, .relux nav.breadcrumb ul li:first-of-type:after {
          display: none; }
        .relux nav.breadcrumb ul li:first-of-type > * {
          padding-left: 20px; }
        .relux nav.breadcrumb ul li:last-of-type {
          border-right: 0; }
  .relux blockquote {
    background: #f2f2f2;
    padding: 95px 0; }
    .relux blockquote p,
    .relux blockquote footer {
      position: relative;
      text-align: center;
      margin: 0 37.5px; }
      @media screen and (min-width: 2460px) {
        .relux blockquote p,
        .relux blockquote footer {
          max-width: 2460px;
          margin: 0 auto; } }
    .relux blockquote p {
      font-style: italic;
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 1px; }
    .relux blockquote footer {
      padding: 16px 0 0; }
      .relux blockquote footer cite {
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 1px; }
  .relux .media-block {
    background: #f2f2f2;
    padding: 40px 0; }
    .relux .media-block .grid {
      margin-top: 0; }
      .relux .media-block .grid [class^="grid-col-"], .relux .media-block .grid [class*=" grid-col-"] {
        margin-bottom: 0; }
      .relux .media-block .grid .image {
        line-height: 0; }
        .relux .media-block .grid .image img {
          position: relative;
          width: 100%;
          height: auto; }
      .relux .media-block .grid h1,
      .relux .media-block .grid h2 {
        margin: 23px 0; }
        @media screen and (min-width: 1267.5px) {
          .relux .media-block .grid h1,
          .relux .media-block .grid h2 {
            margin-top: 0; } }
      .relux .media-block .grid h3 {
        margin: 0 0 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        text-transform: uppercase;
        color: #000; }
      .relux .media-block .grid p .button {
        margin: 4px 14px 0 0; }
    .relux .media-block.full .grid .image {
      line-height: 0; }
      @media screen and (min-width: 1267.5px) {
        .relux .media-block.full .grid .image {
          position: relative;
          float: left;
          width: calc(100% * (0.445));
          margin-left: calc(-(100% / 3) - 37.5px);
          padding: 0 35px 35px 0; } }
      .relux .media-block.full .grid .image > img {
        max-width: 100%;
        height: auto; }
  .relux .hero {
    background: #f2f2f2;
    padding: 60px 0; }
    .relux .hero .grid {
      margin-top: 0; }
      .relux .hero .grid [class^="grid-col-"], .relux .hero .grid [class*=" grid-col-"] {
        margin-bottom: 0; }
      .relux .hero .grid h2, .relux .hero .grid p {
        text-align: center; }
      .relux .hero .grid h2 {
        margin: 0 0 27px; }
      .relux .hero .grid p .button {
        margin: 36px 0 0; }
  .relux .input-box {
    background: #f2f2f2; }
    .relux .input-box h4 {
      text-transform: uppercase;
      font-weight: 600;
      padding: 20px 20px 6px; }
    .relux .input-box ul {
      margin: 0;
      padding: 0 0 16px; }
      .relux .input-box ul li {
        padding: 0 20px;
        line-height: 34px; }
        .relux .input-box ul li:hover {
          background: rgba(191, 191, 191, 0.5); }
        .relux .input-box ul li label {
          display: block; }
          .relux .input-box ul li label a:hover {
            text-decoration: none; }
  .relux .thumbnail .image {
    position: relative;
    overflow: hidden;
    font-size: 0;
    line-height: 0; }
    .relux .thumbnail .image:before {
      content: '';
      display: block;
      padding-top: 56.25%; }
    .relux .thumbnail .image img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0 auto;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
    .relux .thumbnail .image .caption {
      position: absolute;
      left: 30px;
      bottom: 25px;
      padding: 0 10px;
      font-size: 14px;
      line-height: 33px;
      background: #fff;
      font-weight: 400; }
  .relux .thumbnail.auto .image:before {
    display: none; }
  .relux .thumbnail.auto .image img {
    position: relative;
    top: initial;
    left: initial;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  .relux .thumbnail .image + p {
    margin-top: 18px; }
  .relux .js-oncalc-overlay {
    color: #333;
    font-size: 14px; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .t_heading_1, .relux .js-oncalc-overlay .js-onlinecalc-form .oncalc-results__selector {
      background-color: #ededed;
      padding: 5px;
      color: #333; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .oncalc-results__dropwdown-link {
      font-weight: 800;
      font-size: 1.2em; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .workplane li {
      display: inline;
      margin: 0 25px; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .align-center {
      text-align: center; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .form-row {
      margin-top: 3px; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .form-box {
      text-align: left;
      margin-bottom: 5px; }
    .relux .js-oncalc-overlay .js-onlinecalc-form div.input {
      width: 100%;
      margin: 0; }
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input input[type="text"],
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input input[type="password"],
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input input[type="email"],
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input input[type="number"],
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input input[type="search"],
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input textarea,
      .relux .js-oncalc-overlay .js-onlinecalc-form div.input select {
        border-color: #bfbfbf;
        width: 125px; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .help-block {
      font-size: 11px;
      font-style: italic; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .oncalc-results__selector {
      margin: 5px 0; }
    .relux .js-oncalc-overlay .js-onlinecalc-form .oncalc-results__dropwdown-content li {
      display: inline;
      font-size: 16px;
      margin-right: 6px; }
  body.no-scroll {
    overflow: hidden; }
  .relux .modal {
    position: fixed;
    left: 0;
    top: 80px;
    width: 100%;
    height: 100%;
    display: none;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d; }
    .relux .modal.open {
      display: block;
      z-index: 90000; }
    .relux .modal .modal-blocker {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.75); }
    .relux .modal .slideDescriptionPopup-blocker {
      background: rgba(242, 242, 242, 0.1); }
    .relux .modal .modal-popup {
      position: relative;
      top: 50%;
      width: 100%;
      max-width: 500px;
      max-height: 100%;
      margin: 0 auto;
      padding: 30px 20px;
      box-sizing: border-box;
      text-align: center;
      background: #f2f2f2;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      .relux .modal .modal-popup > h1,
      .relux .modal .modal-popup > h2,
      .relux .modal .modal-popup > h3,
      .relux .modal .modal-popup > h4,
      .relux .modal .modal-popup > h5,
      .relux .modal .modal-popup > h6 {
        font-size: 24px;
        line-height: 33px;
        margin: 0 0 10px; }
      .relux .modal .modal-popup p {
        text-align: center; }
      .relux .modal .modal-popup form {
        margin: 5px 0 0; }
      .relux .modal .modal-popup .modal-buttons {
        margin: 0;
        text-align: center; }
        .relux .modal .modal-popup .modal-buttons .button {
          margin: 0 5px; }
      .relux .modal .modal-popup .modal-close {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        color: #bfbfbf; }
        .relux .modal .modal-popup .modal-close:hover {
          color: #e2007a; }
    .relux .modal .slideDescriptionPopup, .relux .modal .reluxDesktopPopup {
      top: 33%;
      max-width: 67%;
      background: rgba(242, 242, 242, 0.85); }
  .relux .carousel,
  .relux .js-carousel {
    position: relative;
    padding: 0 0 40px; }
    .relux .carousel > h1,
    .relux .carousel > h2,
    .relux .carousel > h3,
    .relux .carousel > h4,
    .relux .carousel > h5,
    .relux .carousel > h6,
    .relux .js-carousel > h1,
    .relux .js-carousel > h2,
    .relux .js-carousel > h3,
    .relux .js-carousel > h4,
    .relux .js-carousel > h5,
    .relux .js-carousel > h6 {
      font-size: 24px;
      font-weight: 300;
      line-height: 33px;
      letter-spacing: 1px;
      padding: 70px 37.5px 0;
      background: #f2f2f2; }
      @media screen and (min-width: 2535px) {
        .relux .carousel > h1,
        .relux .carousel > h2,
        .relux .carousel > h3,
        .relux .carousel > h4,
        .relux .carousel > h5,
        .relux .carousel > h6,
        .relux .js-carousel > h1,
        .relux .js-carousel > h2,
        .relux .js-carousel > h3,
        .relux .js-carousel > h4,
        .relux .js-carousel > h5,
        .relux .js-carousel > h6 {
          position: relative;
          max-width: 2460px;
          margin: 0 auto;
          padding-left: 0;
          padding-right: 0; } }
    .relux .carousel .carousel-container,
    .relux .js-carousel .carousel-container {
      position: relative;
      margin: 75px 44px; }
      @media screen and (min-width: 2579px) {
        .relux .carousel .carousel-container,
        .relux .js-carousel .carousel-container {
          max-width: 2535px;
          margin: 75px auto 0; } }
      .relux .carousel .carousel-container .carousel-wrapper,
      .relux .js-carousel .carousel-container .carousel-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
        -webkit-transition: min-height 250ms ease-in-out;
        -moz-transition: min-height 250ms ease-in-out;
        -o-transition: min-height 250ms ease-in-out;
        transition: min-height 250ms ease-in-out; }
        .relux .carousel .carousel-container .carousel-wrapper .carousel-slider,
        .relux .js-carousel .carousel-container .carousel-wrapper .carousel-slider {
          position: relative;
          -webkit-transition: -webkit-transform 250ms ease-in-out 50ms;
          -moz-transition: -moz-transform 250ms ease-in-out 50ms;
          -o-transition: -o-transform 250ms ease-in-out 50ms;
          transition: transform 250ms ease-in-out 50ms; }
          .relux .carousel .carousel-container .carousel-wrapper .carousel-slider .grid,
          .relux .js-carousel .carousel-container .carousel-wrapper .carousel-slider .grid {
            position: absolute;
            margin: 0 !important; }
            .relux .carousel .carousel-container .carousel-wrapper .carousel-slider .grid.no-wrap,
            .relux .js-carousel .carousel-container .carousel-wrapper .carousel-slider .grid.no-wrap {
              flex-wrap: nowrap;
              max-width: initial; }
            .relux .carousel .carousel-container .carousel-wrapper .carousel-slider .grid > *,
            .relux .js-carousel .carousel-container .carousel-wrapper .carousel-slider .grid > * {
              margin-bottom: 0 !important; }
      .relux .carousel .carousel-container .carousel-controls,
      .relux .js-carousel .carousel-container .carousel-controls {
        position: absolute;
        top: 0;
        width: 100%; }
        .relux .carousel .carousel-container .carousel-controls .carousel-prev,
        .relux .carousel .carousel-container .carousel-controls .carousel-next,
        .relux .js-carousel .carousel-container .carousel-controls .carousel-prev,
        .relux .js-carousel .carousel-container .carousel-controls .carousel-next {
          position: absolute;
          width: 22px;
          height: 100%; }
          .relux .carousel .carousel-container .carousel-controls .carousel-prev.disabled,
          .relux .carousel .carousel-container .carousel-controls .carousel-next.disabled,
          .relux .js-carousel .carousel-container .carousel-controls .carousel-prev.disabled,
          .relux .js-carousel .carousel-container .carousel-controls .carousel-next.disabled {
            display: none; }
          .relux .carousel .carousel-container .carousel-controls .carousel-prev a,
          .relux .carousel .carousel-container .carousel-controls .carousel-next a,
          .relux .js-carousel .carousel-container .carousel-controls .carousel-prev a,
          .relux .js-carousel .carousel-container .carousel-controls .carousel-next a {
            position: relative;
            top: 50%;
            display: block;
            font-size: 80px;
            line-height: 80px;
            cursor: pointer;
            color: #9b9b9b;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-transition: color 250ms ease-out;
            -moz-transition: color 250ms ease-out;
            -o-transition: color 250ms ease-out;
            transition: color 250ms ease-out; }
            .relux .carousel .carousel-container .carousel-controls .carousel-prev a:hover,
            .relux .carousel .carousel-container .carousel-controls .carousel-next a:hover,
            .relux .js-carousel .carousel-container .carousel-controls .carousel-prev a:hover,
            .relux .js-carousel .carousel-container .carousel-controls .carousel-next a:hover {
              text-decoration: none;
              color: #000; }
        .relux .carousel .carousel-container .carousel-controls .carousel-prev,
        .relux .js-carousel .carousel-container .carousel-controls .carousel-prev {
          left: -22px; }
        .relux .carousel .carousel-container .carousel-controls .carousel-next,
        .relux .js-carousel .carousel-container .carousel-controls .carousel-next {
          right: -22px; }
    .relux .carousel > p:last-of-type,
    .relux .js-carousel > p:last-of-type {
      text-align: center;
      padding: 60px 0 40px; }
  @media print {
    .relux img.datasheet-image-screen {
      visibility: hidden; } }
  @media screen {
    .relux img.datasheet-image-print {
      visibility: hidden; } }
  .relux .search-container {
    margin: 18.75px 20px; }
    @media screen and (min-width: 633.75px) {
      .relux .search-container {
        margin: 18.75px 20px; } }
    @media screen and (min-width: 1267.5px) {
      .relux .search-container {
        margin: 37.5px 20px; } }
    @media screen and (min-width: 1901.25px) {
      .relux .search-container {
        margin: 56.25px 20px; } }
    @media screen and (min-width: 2535px) {
      .relux .search-container {
        margin: 75px 20px; } }
    @media screen and (min-width: 2535px) {
      .relux .search-container {
        margin: 75px auto;
        width: 2460px; } }
    @media screen and (min-width: 768px) {
      .relux .search-container {
        padding-left: 300px;
        box-sizing: border-box;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transition: padding-left 250ms ease-out;
        -moz-transition: padding-left 250ms ease-out;
        -o-transition: padding-left 250ms ease-out;
        transition: padding-left 250ms ease-out; }
        .relux .search-container:after {
          content: '';
          display: table;
          clear: both; } }
    .relux .search-container .search-sidebar {
      position: relative; }
      @media screen and (min-width: 768px) {
        .relux .search-container .search-sidebar {
          width: 280px;
          float: left;
          margin: 0 0 0 -300px;
          overflow-x: hidden;
          -webkit-transition: all 250ms ease-out;
          -moz-transition: all 250ms ease-out;
          -o-transition: all 250ms ease-out;
          transition: all 250ms ease-out; } }
      .relux .search-container .search-sidebar .big-container {
        height: auto;
        overflow: hidden; }
        .relux .search-container .search-sidebar .big-container .ui_filter-group dt span {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px; }
      .relux .search-container .search-sidebar .search-trigger {
        position: relative;
        width: 100%;
        height: 45px;
        padding: 0 20px;
        margin: 0 0 15px;
        box-sizing: border-box;
        line-height: 45px;
        background: #f2f2f2;
        cursor: pointer;
        display: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
        .relux .search-container .search-sidebar .search-trigger:hover {
          background: rgba(191, 191, 191, 0.5); }
        .relux .search-container .search-sidebar .search-trigger span {
          font-size: 14px;
          letter-spacing: 1px; }
        .relux .search-container .search-sidebar .search-trigger:after {
          content: "\e90a";
          position: absolute;
          top: 50%;
          right: 20px;
          width: 14px;
          height: 14px;
          font-family: "relux" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          font-size: 14px;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #666;
          -webkit-transform: translateY(-50%) rotateZ(0deg);
          -moz-transform: translateY(-50%) rotateZ(0deg);
          -ms-transform: translateY(-50%) rotateZ(0deg);
          -o-transform: translateY(-50%) rotateZ(0deg);
          transform: translateY(-50%) rotateZ(0deg);
          -webkit-transition: -webkit-transform 500ms ease-out;
          -moz-transition: -moz-transform 500ms ease-out;
          -o-transition: -o-transform 500ms ease-out;
          transition: transform 500ms ease-out; }
          @media screen and (min-width: 768px) {
            .relux .search-container .search-sidebar .search-trigger:after {
              content: "\e902"; } }
      .relux .search-container .search-sidebar.built .search-trigger {
        display: block; }
        @media screen and (min-width: 768px) {
          .relux .search-container .search-sidebar.built .search-trigger {
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-transition: width 250ms ease-out;
            -moz-transition: width 250ms ease-out;
            -o-transition: width 250ms ease-out;
            transition: width 250ms ease-out; } }
      .relux .search-container .search-sidebar .search-filters {
        position: fixed;
        left: 0;
        top: 50px;
        width: 100%;
        height: calc(100% - 50px);
        padding: 10px 20px 10px;
        box-sizing: border-box;
        z-index: 70000;
        overflow: auto;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-transition: -webkit-transform 250ms ease-out;
        -moz-transition: -moz-transform 250ms ease-out;
        -o-transition: -o-transform 250ms ease-out;
        transition: transform 250ms ease-out;
        background: #fff; }
        @media screen and (min-width: 768px) {
          .relux .search-container .search-sidebar .search-filters {
            position: relative;
            left: auto;
            top: auto;
            height: auto;
            width: 280px;
            padding: 0;
            z-index: initial;
            overflow: initial;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none; } }
        .relux .search-container .search-sidebar .search-filters .expandable,
        .relux .search-container .search-sidebar .search-filters .input-box,
        .relux .search-container .search-sidebar .search-filters .search {
          display: block;
          margin-bottom: 15px; }
        .relux .search-container .search-sidebar .search-filters .search-close-mobile {
          text-align: center; }
          @media screen and (min-width: 768px) {
            .relux .search-container .search-sidebar .search-filters .search-close-mobile {
              display: none; } }
        .relux .search-container .search-sidebar .search-filters > :last-child {
          margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .relux .search-container.filters-toggle {
        padding-left: 74px; } }
    @media screen and (min-width: 768px) {
      .relux .search-container.filters-toggle .search-sidebar {
        margin-left: -74px;
        width: 54px; }
        .relux .search-container.filters-toggle .search-sidebar .search-trigger {
          width: 54px; }
          .relux .search-container.filters-toggle .search-sidebar .search-trigger span {
            display: none; }
          .relux .search-container.filters-toggle .search-sidebar .search-trigger:after {
            -webkit-transform: translateY(-50%) rotateZ(180deg);
            -moz-transform: translateY(-50%) rotateZ(180deg);
            -ms-transform: translateY(-50%) rotateZ(180deg);
            -o-transform: translateY(-50%) rotateZ(180deg);
            transform: translateY(-50%) rotateZ(180deg); } }
    .relux .search-container.filters-toggle .search-sidebar .search-filters {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
      @media screen and (min-width: 768px) {
        .relux .search-container.filters-toggle .search-sidebar .search-filters {
          -webkit-transform: translateX(-280px);
          -moz-transform: translateX(-280px);
          -ms-transform: translateX(-280px);
          -o-transform: translateX(-280px);
          transform: translateX(-280px); } }
    .relux .search-container .search-results {
      position: relative; }
      .relux .search-container .search-results .breadcrumb,
      .relux .search-container .search-results .sort-results {
        margin: 0 0 20px; }
  .relux .range-slider {
    border-radius: 3px;
    box-shadow: inset 0 2px 3px 0 rgba(70, 70, 70, 0.5);
    width: 204px;
    height: 6px;
    border: 1px solid #fff;
    border-top-color: #707070;
    background: #b7b7b7;
    position: relative;
    margin-top: 8px;
    margin-bottom: 40px;
    margin-left: 30px; }
  .relux .range-slider .noUi-handle > div {
    background-color: #bbb;
    background-image: -webkit-linear-gradient(top, #aaa 0, #ccc 60%);
    background-image: linear-gradient(to bottom, #aaa 0, #ccc 60%);
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 rgba(70, 70, 70, 0.3);
    cursor: pointer;
    height: 12px;
    width: 12px;
    border: 1px solid #fff;
    position: absolute;
    top: -5px;
    z-index: 5; }
  .relux .range-slider .noUi-handle > div:hover,
  .relux .range-slider .noUi-handle > div .noUi-activeHandle {
    background-image: -webkit-linear-gradient(top, #cc007a 0, #f09 80%);
    background-image: linear-gradient(to bottom, #cc007a 0, #f09 80%);
    background-color: #f09; }
  .relux .range-slider .noUi-handle > div .infoBox {
    position: absolute;
    top: 18px;
    width: 80px;
    left: -31px;
    text-align: center; }
  .relux .range-slider .noUi-lowerHandle > div .infoBox {
    background-image: -webkit-linear-gradient(left, #eee 50%, rgba(238, 238, 238, 0.1));
    background-image: linear-gradient(to right, #eee 50%, rgba(238, 238, 238, 0.1)); }
  .relux .range-slider .noUi-upperHandle > div .infoBox {
    background-image: -webkit-linear-gradient(right, #eee 50%, rgba(238, 238, 238, 0.1));
    background-image: linear-gradient(to left, #eee 50%, rgba(238, 238, 238, 0.1)); }
  .relux .range-slider .noUi-midBar,
  .relux .range-slider .bar-1 {
    box-shadow: inset 0 2px 3px 0 rgba(70, 70, 70, 0.5);
    border-top: 1px solid #9A065B;
    height: 6px;
    background-color: #f09;
    z-index: 0; }
  .relux .filterbar__back-link {
    display: block;
    width: 200px;
    position: absolute;
    left: 40px;
    top: 124px;
    text-align: right;
    z-index: 10; }
  .relux .sort-results {
    font-size: 0;
    line-height: 0; }
    .relux .sort-results li {
      position: relative;
      display: inline-block;
      height: 45px;
      line-height: 45px;
      padding: 0 34px 0 20px;
      margin: 0 20px 0 0;
      font-size: 14px;
      letter-spacing: 1px;
      background: #f2f2f2;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .relux .sort-results li:hover {
        background: rgba(191, 191, 191, 0.5); }
      .relux .sort-results li .indicator {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 14px;
        height: 20px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        .relux .sort-results li .indicator:before, .relux .sort-results li .indicator:after {
          position: absolute;
          left: 0;
          width: 14px;
          height: 8px;
          font-family: "relux" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-align: center;
          font-size: 8px;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          -webkit-transition: color 250ms ease-out;
          -moz-transition: color 250ms ease-out;
          -o-transition: color 250ms ease-out;
          transition: color 250ms ease-out; }
        .relux .sort-results li .indicator:before {
          top: 0;
          content: '\e914';
          color: #9b9b9b; }
        .relux .sort-results li .indicator:after {
          bottom: 0;
          content: '\e913';
          color: #9b9b9b; }
      .relux .sort-results li.descending .indicator:before {
        color: #666; }
      .relux .sort-results li.descending .indicator:after {
        color: #9b9b9b; }
      .relux .sort-results li.ascending .indicator:before {
        color: #9b9b9b; }
      .relux .sort-results li.ascending .indicator:after {
        color: #666; }
  .relux .family-container .family-wrapper {
    margin: 18.75px 0; }
    @media screen and (min-width: 633.75px) {
      .relux .family-container .family-wrapper {
        margin: 18.75px 0; } }
    @media screen and (min-width: 1267.5px) {
      .relux .family-container .family-wrapper {
        margin: 37.5px 0; } }
    @media screen and (min-width: 1901.25px) {
      .relux .family-container .family-wrapper {
        margin: 56.25px 0; } }
    @media screen and (min-width: 2535px) {
      .relux .family-container .family-wrapper {
        margin: 75px 0; } }
    @media screen and (min-width: 2535px) {
      .relux .family-container .family-wrapper {
        margin: 75px auto;
        width: 2460px; } }
    .relux .family-container .family-wrapper .breadcrumb {
      margin: 0 20px;
      overflow-x: scroll; }
      @media screen and (min-width: 2535px) {
        .relux .family-container .family-wrapper .breadcrumb {
          margin: 0; } }
    .relux .family-container .family-wrapper .product-overview {
      margin: 15px 20px 0; }
      .relux .family-container .family-wrapper .product-overview:after {
        content: '';
        display: table;
        clear: both; }
      @media screen and (min-width: 2535px) {
        .relux .family-container .family-wrapper .product-overview {
          margin: 15px 0 0; } }
      .relux .family-container .family-wrapper .product-overview .product-images {
        position: relative;
        width: 220px;
        height: 220px;
        background: transparent center center no-repeat;
        background-size: contain; }
        @media screen and (min-width: 768px) {
          .relux .family-container .family-wrapper .product-overview .product-images {
            float: left;
            margin-right: 20px; } }
        .relux .family-container .family-wrapper .product-overview .product-images div {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: hidden; }
          .relux .family-container .family-wrapper .product-overview .product-images div img {
            position: relative;
            width: 100%;
            height: auto; }
      .relux .family-container .family-wrapper .product-overview .product-meta {
        max-width: 767px;
        float: left;
        margin-left: auto;
        margin-right: auto; }
        .relux .family-container .family-wrapper .product-overview .product-meta h1 {
          font-size: 24px;
          line-height: 48px;
          font-weight: 400; }
          .relux .family-container .family-wrapper .product-overview .product-meta h1 small {
            font-weight: 300; }
    .relux .family-container .family-wrapper .product-filter-headline {
      padding: 20px 0 0;
      font-size: 14px;
      line-height: 20px;
      color: #4d4d4d;
      clear: both; }
    .relux .family-container .family-wrapper .product-filter {
      clear: both;
      padding: 10px 0 0;
      font-size: 0; }
      .relux .family-container .family-wrapper .product-filter li {
        position: relative;
        display: inline-block;
        width: calc((100% - 90px) / 10);
        margin: 0 10px 10px 0;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        overflow: hidden; }
        .relux .family-container .family-wrapper .product-filter li:nth-of-type(10) {
          margin-right: 0; }
        .relux .family-container .family-wrapper .product-filter li:before {
          position: relative;
          content: '';
          display: block;
          padding: 100% 0 0; }
        .relux .family-container .family-wrapper .product-filter li:after {
          position: absolute;
          content: '';
          width: 0;
          height: 3px;
          bottom: 0;
          left: 50%;
          background: #e2007a;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          -webkit-transition: width 450ms ease-out;
          -moz-transition: width 450ms ease-out;
          -o-transition: width 450ms ease-out;
          transition: width 450ms ease-out; }
        .relux .family-container .family-wrapper .product-filter li img {
          position: absolute;
          top: 0;
          width: 100%;
          height: auto; }
        .relux .family-container .family-wrapper .product-filter li.selected:after, .relux .family-container .family-wrapper .product-filter li:hover:after {
          width: 100%; }
  .relux .family-container .family-table h1, .relux .family-container .family-table h2, .relux .family-container .family-table h3, .relux .family-container .family-table h4, .relux .family-container .family-table h5, .relux .family-container .family-table h6 {
    background: #fff;
    line-height: 50px; }
  .relux .family-container .family-table table {
    table-layout: fixed; }
    .relux .family-container .family-table table tr td,
    .relux .family-container .family-table table tr th {
      font-size: 13px;
      padding: 6px 0 6px 20px;
      cursor: pointer; }
      .relux .family-container .family-table table tr td:last-child,
      .relux .family-container .family-table table tr th:last-child {
        padding-right: 20px; }
    .relux .family-container .family-table table tr th div,
    .relux .family-container .family-table table tr td div {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .relux .family-container .family-table table tr.active td {
      /* background-color: $greySeven; */
      color: #e2007a; }
  .relux .family-container .family-table.family-table-articles table tr th:nth-of-type(1),
  .relux .family-container .family-table.family-table-articles table tr td:nth-of-type(1) {
    width: 30%; }
  .relux .family-container .family-table.family-table-articles table tr th:nth-of-type(2),
  .relux .family-container .family-table.family-table-articles table tr td:nth-of-type(2) {
    width: 70%; }
  .relux .family-container .family-table.family-table-variants table tr th:nth-of-type(1),
  .relux .family-container .family-table.family-table-variants table tr td:nth-of-type(1) {
    width: 30%; }
  .relux .family-container .family-table.family-table-variants table tr th:nth-of-type(2),
  .relux .family-container .family-table.family-table-variants table tr td:nth-of-type(2) {
    width: 70%; }
  .relux .family-footer {
    color: #fff;
    background: #000;
    border-bottom: 2px solid #fff; }
    .relux .family-footer .family-footer-teaser-wrapper {
      border-bottom: 2px solid #e2007a; }
      .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser {
        padding: 26.5px 20px 0; }
        .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser:after {
          content: '';
          display: table;
          clear: both; }
        @media screen and (min-width: 2535px) {
          .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser {
            margin: 0 auto;
            width: 2460px;
            padding-left: 0;
            padding-right: 0; } }
        .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name {
          float: left; }
          .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name h2 {
            font-size: 24px;
            font-weight: 300;
            line-height: 37px; }
            .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name h2 .favorite {
              margin: 0 0 0 5px;
              cursor: pointer; }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name h2 .favorite:before {
                font-family: 'relux' !important;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                content: "\e917";
                font-size: 18px;
                color: #e2007a; }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name h2 .favorite.active:before {
                content: "\e918"; }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name h2 .favorite em {
                display: none; }
          .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-name .family-contacts {
            display: inline-block;
            font-size: 14px;
            line-height: 40px; }
        .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools {
          float: right;
          font-size: 0; }
          .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools > span {
            display: inline-block;
            vertical-align: top;
            margin: 0 0 0 10px; }
          .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads {
            font-size: 0;
            margin: 0 0 10px 10px; }
            .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads:before {
              font-family: 'relux' !important;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              content: "\e908";
              font-size: 10px;
              color: #fff; }
            .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads li {
              display: inline-block;
              margin: 0 0 0 5px;
              font-size: 0;
              line-height: 0; }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads li:before {
                content: '|';
                display: inline-block;
                font-size: 13px;
                line-height: 40px;
                margin: 0 5px 0 0;
                color: rgba(255, 255, 255, 0.5); }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads li:first-of-type {
                margin: 0 0 0 10px; }
                .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads li:first-of-type:before {
                  display: none; }
              .relux .family-footer .family-footer-teaser-wrapper .family-footer-teaser .family-tools .pro .pro-downloads li a {
                font-size: 13px;
                line-height: 40px; }
    .relux .family-footer .family-footer-content {
      margin: 0 37.5px;
      font-size: 0; }
      @media screen and (min-width: 2460px) {
        .relux .family-footer .family-footer-content {
          max-width: 2460px;
          margin: 0 auto; } }
      .relux .family-footer .family-footer-content:after {
        content: '';
        display: table;
        clear: both; }
      .relux .family-footer .family-footer-content .family-footer-content__description {
        width: calc(100% - 239px);
        margin-right: 19px;
        float: left; }
        @media all and (max-width: 799px) {
          .relux .family-footer .family-footer-content .family-footer-content__description {
            width: 100%;
            margin: 0px;
            float: none; } }
        .relux .family-footer .family-footer-content .family-footer-content__description p {
          margin: 0; }
      .relux .family-footer .family-footer-content .family-footer-content__online-calc h4 {
        margin: 32px 0 5px;
        font-size: 14px;
        line-height: 20px; }
        .relux .family-footer .family-footer-content .family-footer-content__online-calc h4:after {
          content: '';
          display: table;
          clear: both; }
        @media all and (min-width: 1024px) {
          .relux .family-footer .family-footer-content .family-footer-content__online-calc h4 {
            margin-bottom: 15px; } }
        .relux .family-footer .family-footer-content .family-footer-content__online-calc h4 span {
          float: left;
          display: inline-block;
          width: 220px;
          text-transform: uppercase; }
        .relux .family-footer .family-footer-content .family-footer-content__online-calc h4 a {
          float: left;
          color: #e2007a;
          margin-left: 20px; }
      .relux .family-footer .family-footer-content .family-footer-content__online-calc .family-footer-content__online-calc__filter select {
        width: 220px; }
      .relux .family-footer .family-footer-content .family-footer-content__online-calc .family-footer-content__online-calc__filter .family-footer-content__online-calc__results {
        display: inline-block;
        font-size: 14px;
        line-height: 29px;
        margin: 0 0 0 20px; }
        .relux .family-footer .family-footer-content .family-footer-content__online-calc .family-footer-content__online-calc__filter .family-footer-content__online-calc__results span:nth-of-type(odd) {
          color: #e2007a;
          font-weight: 400; }
      .relux .family-footer .family-footer-content .family-footer-content__images:after {
        content: '';
        display: table;
        clear: both; }
      @media all and (min-width: 799px) {
        .relux .family-footer .family-footer-content .family-footer-content__images {
          width: 220px;
          padding: 0;
          float: left; } }
      .relux .family-footer .family-footer-content .family-footer-content__images .rlx-js-image-selector img {
        display: none; }
      .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__image {
        display: inline-block;
        width: 220px;
        margin-left: -240px;
        float: left; }
        @media all and (min-width: 1024px) {
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__image {
            margin: 0 0 10px;
            float: none; } }
        .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__image img {
          width: 100%;
          height: auto; }
      .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider {
        display: block;
        vertical-align: top;
        font-size: 0; }
        @media all and (min-width: 1024px) {
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider {
            width: 220px; } }
        .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li {
          position: relative;
          display: inline-block;
          width: calc((100% - 50px) / 6);
          margin: 0 10px 10px 0;
          font-size: 0;
          line-height: 0;
          overflow: hidden;
          cursor: pointer; }
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li:nth-of-type(6) {
            margin-right: 0; }
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li:before {
            content: '';
            display: block;
            padding: 100% 0 0; }
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li:after {
            position: absolute;
            content: '';
            width: 0;
            height: 3px;
            bottom: 0;
            left: 50%;
            background: #e2007a;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            -webkit-transition: width 450ms ease-out;
            -moz-transition: width 450ms ease-out;
            -o-transition: width 450ms ease-out;
            transition: width 450ms ease-out; }
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li img {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;
            left: 0; }
          .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li.selected:after, .relux .family-footer .family-footer-content .family-footer-content__images .family-footer-content__images-slider li:hover:after {
            width: 100%; }
      @media all and (min-width: 1024px) {
        .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper {
          /*width: calc(100% - 240px);*/
          width: 100%;
          margin: 0px 0px 0px 20px;
          float: left; } }
      @media all and (max-width: 1024px) {
        .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper {
          width: 100%;
          margin: 10px;
          float: left; } }
      @media all and (min-width: 1024px) {
        .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta {
          width: calc(50% - 10px);
          float: left; }
          .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta:first-child {
            margin-right: 20px; } }
      .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta h4 {
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: 2px solid #fff; }
      .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta table {
        margin: 0 0 40px;
        table-layout: fixed; }
        .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta table tr td {
          color: #fff;
          background: #000;
          padding: 3px 20px 3px 0;
          vertical-align: top;
          text-align: left; }
          .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta table tr td:first-child {
            width: calc($productImageSize - 50px); }
            @media all and (min-width: 1024px) {
              .relux .family-footer .family-footer-content .family-footer-content__meta-wrapper .family-footer-content__meta table tr td:first-child {
                width: auto; } }
  .relux .hidden {
    display: none; }
  .relux .js-onlinecalc-results {
    font-size: 12pt; }
  .relux .js-oncalc-overlay-show {
    margin-left: 20px; }
    .relux .js-oncalc-overlay-show img {
      padding: 0 5px; }
  .relux div.js-productbig-images div img[class*="js-productimage-"].selected {
    display: block; }
  .relux div.js-productbig-images div img[class*="js-productimage-"].no-match {
    display: none; }
  .relux .video {
    background: #000;
    line-height: 0; }
    .relux .video video {
      width: 100%;
      height: auto; }
  .relux .hidden {
    display: none; }
  .relux .product-image,
  .relux .ldc-image,
  .relux .cad-image {
    width: 250px;
    height: 250px; }
  .relux .ldc-image.emergency-ldc-image {
    border-top: 5px solid green;
    border-bottom: 5px solid green; }
  .relux .img-responsive {
    max-width: 100%;
    height: auto;
    display: block; }
  .relux .component--newslist figure {
    margin: 0;
    padding: 0; }
  .relux .component--newslist .news-item {
    margin-bottom: 2em; }
  .relux .component--newslist figure {
    margin-top: 20px; }
  .relux .component--newslist .Light-in-the-dark- {
    width: 75%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.64;
    letter-spacing: 1px;
    color: #000000;
    color: var(--#000000);
    margin-top: 1.4em; }
  .relux .extra-min-height {
    min-height: 220px; }
  .relux .password-reset {
    margin-top: 15px; }
  .relux .login-centered-form, .relux .register-centered-form {
    padding: 21px;
    background-color: #f2f2f2; }
    .relux .login-centered-form h3, .relux .login-centered-form .content-aligned, .relux .register-centered-form h3, .relux .register-centered-form .content-aligned {
      text-align: center; }
    .relux .login-centered-form input[type=text], .relux .login-centered-form input[type=password], .relux .login-centered-form input [type=email], .relux .register-centered-form input[type=text], .relux .register-centered-form input[type=password], .relux .register-centered-form input [type=email] {
      width: 100%; }
    .relux .login-centered-form .input-aligned, .relux .register-centered-form .input-aligned {
      text-align: center;
      margin-bottom: 1.4em; }
    .relux .login-centered-form .input, .relux .register-centered-form .input {
      margin-top: 0.7em;
      margin-bottom: 0.7em;
      width: 100%; }
    .relux .login-centered-form hr, .relux .register-centered-form hr {
      width: 50%;
      text-align: center;
      margin-top: 1.1em;
      margin-bottom: 1.1em; }
    .relux .login-centered-form .signup-button, .relux .register-centered-form .signup-button {
      margin-top: 12px;
      margin-bottom: 12px; }
    .relux .login-centered-form .login_registration-teaser p, .relux .register-centered-form .login_registration-teaser p {
      text-align: center;
      margin-top: 0.3em;
      margin-bottom: 0.3em; }
    .relux .login-centered-form .help-inline, .relux .register-centered-form .help-inline {
      text-align: center;
      color: red;
      font-weight: 600; }
  .relux .autocompleter-results .js-tags-choices li {
    padding: 3px 0; }
  .relux .autocompleter-results .js-tags-choices li:hover, .relux .autocompleter-results .js-tags-choices li.selected {
    background-color: #9b9b9b; }
  .relux .js-favorite-flag-tooltip {
    position: absolute;
    background: #ccc;
    border: 2px solid #aaa;
    padding: 10px;
    color: #e2007a;
    top: -3000px; }
  .relux .js-favorite-flag-tooltip:after, .relux .js-favorite-flag-tooltip:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .relux .js-favorite-flag-tooltip:after {
    border-color: rgba(204, 204, 204, 0);
    border-top-color: #ccc;
    border-width: 10px;
    margin-left: -10px; }
  .relux .js-favorite-flag-tooltip:before {
    border-color: rgba(170, 170, 170, 0);
    border-top-color: #aaa;
    border-width: 13px;
    margin-left: -13px; }
  .relux .image-cell-favorites .favorite-image {
    width: 100px;
    height: 100px; }
  .relux .js-favorites-table tbody .js-fav td,
  .relux .js-favorites-table thead tr th {
    margin: 5px;
    padding: 5px; }
  .relux .datasheet .headerGrid {
    background-color: white;
    color: black;
    margin-top: 0;
    padding-top: 11px; }
  .relux .datasheet .js-tabs .active {
    font-weight: 600;
    text-decoration: underline; }
  .relux .test-mark--print {
    display: none; }
  .relux .datasheet-description-text {
    padding: 12px 37.5px; }
  .relux .datasheet-overview .table-grid, .relux .datasheet-technical-details .table-grid {
    background: none; }
  .relux .datasheet-overview .table h3, .relux .datasheet-overview .datasheet-description h3, .relux .datasheet-technical-details .table h3, .relux .datasheet-technical-details .datasheet-description h3 {
    line-height: 36px; }
  .relux table.datasheet-table tbody tr td:first-child {
    width: 50%; }
  .relux table.datasheet-table tbody tr td:last-child {
    width: 50%; }
  .relux .tabgrid {
    margin-left: 60px;
    margin-right: 60px; }
    .relux .tabgrid .tabgrid-item {
      float: left;
      width: 46%;
      margin-left: 2%;
      margin-right: 2%;
      margin-bottom: 1%; }
      .relux .tabgrid .tabgrid-item strong {
        font-weight: 600; }
      .relux .tabgrid .tabgrid-item .tabgrid-description {
        margin-top: 32px; }
      .relux .tabgrid .tabgrid-item .tabgrid-buttons {
        margin-top: 32px; }
        .relux .tabgrid .tabgrid-item .tabgrid-buttons .button {
          margin-right: 32px; }
      .relux .tabgrid .tabgrid-item ul li {
        list-style: disc;
        font-size: 14px;
        line-height: 20px;
        margin-left: 35px; }
    .relux .tabgrid .tabgrid-item-description {
      text-align: justify; }
    .relux .tabgrid .tabgrid-description-4 {
      margin-left: 1.5%;
      width: 30.33333%;
      margin-right: 1.5%;
      float: left; }
    @media screen and (max-width: 1280px) {
      .relux .tabgrid .tabgrid-item {
        width: 100%; }
      .relux .tabgrid .tabgrid-item-description {
        text-align: center; } }
    @media screen and (max-width: 768px) {
      .relux .tabgrid .tabgrid-description-4 {
        margin-left: 1.5%;
        width: 97%;
        margin-right: 1.5%;
        float: none; } }
  .relux .relux-desktop .addons {
    width: 100%;
    background: #9b9b9b;
    margin-top: 32px;
    margin-bottom: 64px; }
    .relux .relux-desktop .addons h2 {
      padding-top: 48px;
      padding-bottom: 64px; }
    .relux .relux-desktop .addons .addons-item-3 {
      margin-left: 1%;
      margin-right: 2%;
      width: 22%;
      float: left;
      background: #9b9b9b; }
      .relux .relux-desktop .addons .addons-item-3 .addons-item-inner {
        width: 96%;
        margin-left: 2%;
        margin-right: 2%; }
        .relux .relux-desktop .addons .addons-item-3 .addons-item-inner img {
          width: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto; }
      .relux .relux-desktop .addons .addons-item-3 .addons-item-description {
        text-align: center;
        margin: 24px 0 0 0; }
      .relux .relux-desktop .addons .addons-item-3 .addons-item-description-links {
        margin-top: 16px;
        margin-bottom: 48px; }
        .relux .relux-desktop .addons .addons-item-3 .addons-item-description-links a {
          color: #e2007a; }
    .relux .relux-desktop .addons .addons-item-6 {
      margin-left: 1%;
      margin-right: 2%;
      width: 13.667%;
      float: left;
      background: #9b9b9b; }
      .relux .relux-desktop .addons .addons-item-6 .addons-item-inner {
        width: 96%;
        margin-left: 2%;
        margin-right: 2%; }
        .relux .relux-desktop .addons .addons-item-6 .addons-item-inner img {
          width: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto; }
      .relux .relux-desktop .addons .addons-item-6 .addons-item-description {
        text-align: center;
        margin: 24px 0 0 0; }
      .relux .relux-desktop .addons .addons-item-6 .addons-item-description-links {
        margin-top: 16px;
        margin-bottom: 48px; }
        .relux .relux-desktop .addons .addons-item-6 .addons-item-description-links a {
          color: #e2007a; }
    .relux .relux-desktop .addons .addons-item-5 {
      margin-left: 1%;
      margin-right: 2%;
      width: 17%;
      float: left;
      background: #9b9b9b; }
      .relux .relux-desktop .addons .addons-item-5 .addons-item-inner {
        width: 96%;
        margin-left: 2%;
        margin-right: 2%; }
        .relux .relux-desktop .addons .addons-item-5 .addons-item-inner img {
          width: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto; }
      .relux .relux-desktop .addons .addons-item-5 .addons-item-description {
        text-align: center;
        margin: 24px 0 0 0; }
      .relux .relux-desktop .addons .addons-item-5 .addons-item-description-links {
        margin-top: 16px;
        margin-bottom: 48px; }
        .relux .relux-desktop .addons .addons-item-5 .addons-item-description-links a {
          color: #e2007a; }
    .relux .relux-desktop .addons .addons-item-2 {
      margin-left: 1.01%;
      margin-right: 1.65%;
      width: 14%;
      float: left;
      background: #d0d0d0; }
      .relux .relux-desktop .addons .addons-item-2 .addons-item-inner {
        width: 96%;
        margin-left: 2%;
        margin-right: 2%; }
        .relux .relux-desktop .addons .addons-item-2 .addons-item-inner img {
          width: 55px;
          display: block;
          margin-left: auto;
          margin-right: auto; }
      .relux .relux-desktop .addons .addons-item-2 .addons-item-description {
        text-align: center;
        margin: 24px 0 0 0; }
      .relux .relux-desktop .addons .addons-item-2 .addons-item-description-links {
        margin-top: 16px;
        margin-bottom: 48px; }
        .relux .relux-desktop .addons .addons-item-2 .addons-item-description-links a {
          color: #e2007a; }
    @media screen and (max-width: 768px) {
      .relux .relux-desktop .addons .addons-item-3, .relux .relux-desktop .addons .addons-item-5, .relux .relux-desktop .addons .addons-item-2 {
        width: 100%;
        margin: 0;
        float: left;
        background: #d0d0d0; }
        .relux .relux-desktop .addons .addons-item-3 .addons-item-description, .relux .relux-desktop .addons .addons-item-5 .addons-item-description, .relux .relux-desktop .addons .addons-item-2 .addons-item-description {
          text-align: center; } }
  .relux .relux-desktop .relux-desktop-services .relux-desktop-services-item-inner {
    width: 84%;
    margin-left: auto;
    margin-right: auto; }
    .relux .relux-desktop .relux-desktop-services .relux-desktop-services-item-inner img {
      width: 85%;
      display: block;
      margin-left: auto;
      margin-right: auto; }
    .relux .relux-desktop .relux-desktop-services .relux-desktop-services-item-inner .relux-desktop-services-title {
      font-weight: 900;
      margin-top: 16px;
      margin-bottom: 8px;
      text-align: center; }
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 {
      margin-left: 1.25%;
      width: 22.5%;
      margin-right: 1.25%;
      float: left; } }
  @media screen and (max-width: 768px) {
    .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 {
      width: 100%;
      float: left;
      margin-bottom: 32px; }
      .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 .relux-desktop-services-item-inner {
        width: 100%;
        margin-left: 0;
        margin-right: 0; }
        .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 .relux-desktop-services-item-inner img {
          width: 60%;
          display: block;
          margin-left: auto;
          margin-right: auto; }
        .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 .relux-desktop-services-item-inner .relux-desktop-services-title {
          font-weight: 900;
          margin-top: 16px;
          margin-bottom: 8px;
          text-align: center; }
        .relux .relux-desktop .relux-desktop-services .grid .grid-col-3 .relux-desktop-services-item-inner .relux-desktop-services-description {
          text-align: center; } }
  .relux .watchlist-button {
    position: fixed;
    top: 140px;
    right: 0;
    width: 50px;
    height: 50px;
    background: url(../img/product_comparison.png) 10px 5px no-repeat;
    background-color: #efefef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #333;
    text-decoration: none;
    overflow: hidden;
    text-indent: -9000px;
    z-index: 30; }
  .relux #watchlist {
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: scroll;
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    z-index: 200; }
  .relux .ie8 #watchlist {
    background-color: #333; }
  .relux .watchlist-table {
    width: auto; }
  .relux .close-watchlist {
    height: 100%;
    margin: -20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-indent: -9999em;
    z-index: 35; }
  .relux .watchlist-content {
    cursor: default;
    background-color: #efefef;
    display: inline-block;
    margin: 80px auto;
    padding: 40px;
    position: relative;
    z-index: 40; }
  .relux .close-watchlist-button {
    background: url("../img/close.png") left top no-repeat;
    cursor: pointer;
    height: 32px;
    overflow: hidden;
    position: absolute;
    top: -19px;
    right: -19px;
    width: 32px;
    z-index: 110; }
  .relux .close-watchlist-button:hover {
    background-position: 0 -32px; }
  .relux .watchlist-table {
    cursor: default;
    box-shadow: none;
    border: none;
    float: none;
    table-layout: fixed; }
  .relux .watchlist-table tr {
    cursor: default; }
  .relux .watchlist-table a {
    cursor: pointer; }
  .relux .watchlist-table th,
  .relux .watchlist-table td {
    padding-right: 10px;
    padding-left: 10px; }
  .relux .watchlist-table td {
    white-space: normal;
    line-height: 18px;
    padding: 3px 10px; }
  .relux .watchlist-table td a {
    color: #f09; }
  .relux .watchlist-table thead {
    background: transparent;
    background-image: none;
    border: none; }
  .relux .watchlist-table thead tr.product-images {
    background: transparent;
    border: none;
    box-shadow: none; }
  .relux .watchlist-table thead tr.product-images th {
    padding-bottom: 10px;
    position: relative;
    width: 220px;
    max-width: 220px; }
  .relux .watchlist-table thead tr.product-images th.legend {
    width: 160px;
    max-width: 160px;
    text-align: right;
    vertical-align: bottom; }
  .relux .watchlist-table thead tr.product-images th img {
    border: 1px solid #ccc; }
  .relux .watchlist-table thead tr.product-images th .image-switch {
    margin-bottom: 10px; }
  .relux .watchlist-table thead tr.product-images th .image-switch a {
    font-weight: bold;
    color: gray;
    text-decoration: none;
    text-transform: uppercase; }
  .relux .watchlist-table thead tr.product-images th .image-switch a:hover {
    color: #f09; }
  .relux .watchlist-table thead tr.product-images th .image-switch a.active {
    color: black; }
  .relux .watchlist-table tbody tr {
    background-color: #efefef; }
  .relux .watchlist-table tbody tr.section-header {
    border: none; }
  .relux .watchlist-table tbody tr.section-header td {
    border: none;
    border-bottom: 1px solid #ccc; }
  .relux .watchlist-table tbody tr.section-header td.section-name {
    border-left: none;
    font-weight: bold;
    text-align: right;
    border-top: none !important;
    border-bottom: none !important; }
  .relux .watchlist-table tbody tr.end-of-section td,
  .relux .watchlist-table tbody tr.spacing-row td {
    border: none;
    border-top: 1px solid #fff; }
  .relux .watchlist-table tbody tr.end-of-section td.label-column,
  .relux .watchlist-table tbody tr.spacing-row td.label-column {
    border: none !important; }
  .relux .watchlist-table tbody tr.spacing-row td {
    border-bottom: 1px solid #ccc; }
  .relux .watchlist-table tbody td {
    text-align: left;
    max-width: 220px; }
  .relux .watchlist-table tbody .attribute-name {
    border-left: none;
    background-color: #efefef;
    font-weight: regular;
    text-align: right;
    border-top: none !important;
    border-bottom: none !important; }
  .relux .watchlist-table .section-header + .attribute,
  .relux .watchlist-table .spacing-row + .attribute,
  .relux .watchlist-table .section-header + .attribute + .attribute + .attribute,
  .relux .watchlist-table .spacing-row + .attribute + .attribute + .attribute,
  .relux .watchlist-table .section-header + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table .spacing-row + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table .section-header + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table .spacing-row + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute .section-header + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table .spacing-row + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table .section-header + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute,
  .relux .watchlist-table + .attribute + .attribute,
  .relux .watchlist-table .spacing-row + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute + .attribute {
    background-color: #fff; }
  .relux .watchlist-table tfoot tr,
  .relux .watchlist-table tfoot td {
    background-color: transparent;
    border: none; }
  .relux .watchlist-table tfoot td {
    padding-top: 18px;
    text-align: center; }
  .relux .watchlist-table tfoot td a.close {
    background-image: -webkit-linear-gradient(top, #e1e1e1 0, #d2d2d2 100%);
    background-image: linear-gradient(to bottom, #e1e1e1 0, #d2d2d2 100%);
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(100, 100, 100, 0.6);
    text-shadow: #fcfcfc 0 1px;
    cursor: pointer;
    border: 1px solid #fcfcfc;
    border-bottom-color: #d2d2d2;
    color: #666;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 200px;
    text-decoration: none;
    display: inline-block;
    float: none;
    width: 140px; }
  .relux .watchlist-table tfoot td a.close:hover {
    color: #333; }
  .relux .watchlist-table tfoot td a.close:active {
    box-shadow: 0 -1px 1px 0 rgba(100, 100, 100, 0.6);
    border-color: #d2d2d2;
    border-bottom-color: #fcfcfc;
    top: 1px; }
  .relux .watchlist-table tfoot td a.close.disabled,
  .relux .watchlist-table tfoot td a.close.disabled:active {
    box-shadow: 0 1px 1px 0 rgba(100, 100, 100, 0.6);
    border: 1px solid #fcfcfc;
    border-bottom-color: #d2d2d2;
    cursor: not-allowed;
    color: #999;
    top: 0; }
  .relux .watchlist__icon {
    background-image: url("../img/watchlist_sprite.png");
    background-repeat: no-repeat;
    display: inline-block;
    margin: 10px 5px 0;
    height: 40px;
    width: 40px; }
  .relux .watchlist__rolf-export {
    background-position: 0 0; }
  .relux .watchlist__rolf-export:hover {
    background-position: 0 -40px; }
  .relux .watchlist__ldt-export {
    background-position: -40px 0; }
  .relux .watchlist__ldt-export:hover {
    background-position: -40px -40px; }
  .relux .watchlist__ies-export {
    background-position: -80px 0; }
  .relux .watchlist__ies-export:hover {
    background-position: -80px -40px; }
  .relux .watchlist__datasheet-link {
    background-position: -120px 0; }
  .relux .watchlist__datasheet-link:hover {
    background-position: -120px -40px; }
  .relux .watchlist__delete-link {
    background-position: -160px 0; }
  .relux .watchlist__delete-link:hover {
    background-position: -160px -40px; }
  .relux .ui_filter-group:after {
    content: "";
    display: table;
    clear: both; }
  .relux .ui_filter-group h4 {
    position: relative;
    display: table-cell;
    padding: 4.5px 46px 4.5px 20px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    vertical-align: middle;
    word-break: break-word; }
  .relux .ui_filter-group.disabled {
    display: none !important; }
  .relux .ui_filter-group a {
    color: #333;
    text-decoration: none; }
  .relux .ui_filter-group .checkbox {
    background: transparent url("../img/sprite_old.png") 2px -97px no-repeat;
    cursor: pointer;
    display: block;
    padding: 0px 0 2px 16px;
    background: transparent url("../img/sprite_old.png") 2px -97px no-repeat;
    color: #333;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    clear: both;
    width: 204px; }
  .relux .ui_filter-group .checkbox:active {
    background-position: 2px -147px;
    cursor: pointer; }
  .relux .ui_filter-group .checkbox .count {
    float: right;
    padding-right: 2px; }
  .relux .ui_filter-group .checkbox:hover {
    background-color: #f9f9f9;
    background-position: 2px -147px;
    color: #666;
    cursor: pointer; }
  .relux .ui_filter-group .checkbox.selected,
  .relux .ui_filter-group .checkbox.active {
    background-color: transparent;
    background-position: 2px -247px;
    color: #333;
    cursor: pointer; }
  .relux .ui_filter-group .checkbox.selected:hover,
  .relux .ui_filter-group .checkbox.active:hover {
    background-color: #eee;
    color: #333;
    cursor: pointer; }
  .relux .ui_filter-group .checkbox.disabled {
    display: none; }
  .relux .ui_filter-group .checkbox:after {
    content: "";
    display: table;
    clear: both; }
  .relux .ui_filter-group .checkbox .gav-value {
    float: left;
    width: 155px; }
  .relux .ui_filter-group .checkbox .count {
    max-width: 47px; }
  .relux .ui_filter-group .show-more {
    cursor: pointer;
    position: relative; }
  .relux .ui_filter-group .show-more:hover {
    color: #f09; }
  .relux .ui_filter-group .show-more .symbol {
    background-image: url("../img/sort_indicators.png");
    background-position: 0 -50px;
    position: absolute;
    top: 10px;
    right: 4px;
    width: 14px;
    height: 20px; }
  .relux .ui_filter-group .show-more.less .symbol {
    background-position: -50px -50px; }
  .relux .ui_filter-group .filter-group-6001 h4,
  .relux .ui_filter-group .filter-group-6000 h4 {
    display: none; }
  .relux .ui_filter-group .filter-group-6001 ul {
    margin-left: 13px; }
  .relux .ui_filter-group .filter-group-6001 ul .checkbox {
    width: 191px; }
  .relux .ui_filter-group .filter-group-6000 ul {
    margin-left: 26px; }
  .relux .ui_filter-group .filter-group-6000 ul .checkbox {
    width: 178px; }
  .relux .ui_filter-group .applications-list li > ul {
    display: none; }
  .relux .ui_filter-group .applications-list li .selected + ul {
    display: block; }
  .relux .ui_filter-group .applications-list li li {
    margin-left: 13px; }
  .relux .ui_filter-group .applications-list li li .checkbox {
    width: 191px; }
  .relux .ui_filter-group .applications-list li li .checkbox .gav-value {
    width: 142px; }
  .relux .ui_filter-group .applications-list li li li .checkbox {
    width: 178px; }
  .relux .ui_filter-group .applications-list li li li .checkbox .gav-value {
    width: 129px; }
  .relux .ui_filter-group li {
    line-height: 20px;
    margin-left: 20px; }
  .relux .ui_filter-group {
    background: #f2f2f2; }
  .relux .js-filterbar {
    margin-left: 17px; }
  .relux .family-wrapper {
    margin-left: 295px;
    display: table; }
  @media screen and (max-width: 951px) {
    .relux .js-filterbar {
      display: none; }
    .relux .family-wrapper {
      margin-left: 0px;
      display: block; } }
  .relux form#account-administration input[type=checkbox] {
    width: 16px;
    height: 16px;
    clip: auto;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox; }
  .relux form.account-invitation-form input[type=checkbox].rlx-form-control,
  .relux tr.js-userproduct td input[type=checkbox].legacy-checkbox,
  .relux th.th-select input[type=checkbox].js-table-all-select {
    width: 12px;
    height: 12px;
    clip: auto;
    margin-left: -19px;
    margin-top: 5px;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox; }
  .relux tr.js-userproduct td input[type=checkbox].legacy-checkbox,
  .relux th.th-select input[type=checkbox].js-table-all-select {
    margin-left: 5px; }
  .relux form.account-invitation-form input#id_email {
    width: 100%; }
  .relux form.account-invitation-form div.user-permissions {
    margin-left: 21px; }
  .relux .labelsWrapper {
    margin-top: -25px;
    padding-bottom: 50px; }
    .relux .labelsWrapper .leftData {
      float: left;
      width: 46%;
      text-align: left;
      padding-left: 4%;
      font-size: 0.9em; }
      .relux .labelsWrapper .leftData input[type=number] {
        width: 6.6em;
        text-align: left;
        font-size: 0.9em;
        background: white; }
    .relux .labelsWrapper .rightData {
      float: right;
      width: 46%;
      text-align: right;
      padding-right: 4%;
      font-size: 0.9em; }
      .relux .labelsWrapper .rightData input[type=number] {
        width: 6.54em;
        text-align: right;
        font-size: 0.9em;
        background: white; }
  .relux .sliderTitle {
    font-size: 0.9em; }
  .relux .sliderTitle dt:first-child {
    margin-bottom: 15px; }
  .relux .sliderTitle dt:first-child span {
    padding: 4.5px 46px 4.5px 20px; }
  .relux .icon,
  .relux .icon--medium,
  .relux .icon--large {
    background-image: url("../img/rlx_sprite_5.png");
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 14px;
    height: 14px;
    width: 14px;
    vertical-align: text-top; }
  .relux .icon--left {
    margin-right: 3px; }
  .relux .icon--right {
    margin-left: 3px; }
  .relux .icon--medium {
    line-height: 16px;
    height: 16px;
    width: 16px; }
  .relux .icon--large {
    line-height: 24px;
    height: 24px;
    width: 24px; }
  .relux .icon__user {
    background-position: 0 -400px; }
  .relux .icon__user:hover {
    background-position: 0 -440px; }
  .relux .icon__user.icon--nohover:hover {
    background-position: 0 -400px; }
  .relux .icon__globe {
    background-position: -40px -400px; }
  .relux .icon__globe:hover {
    background-position: -40px -440px; }
  .relux .icon__globe.icon--nohover:hover {
    background-position: -40px -400px; }
  .relux .icon__signup {
    background-position: -80px -400px; }
  .relux .icon__signup:hover {
    background-position: -80px -440px; }
  .relux .icon__signup.icon--nohover:hover {
    background-position: -80px -400px; }
  .relux .icon__onoff {
    background-position: -120px -400px; }
  .relux .icon__onoff:hover {
    background-position: -120px -440px; }
  .relux .icon__onoff.icon--nohover:hover {
    background-position: -120px -400px; }
  .relux .icon__checkmark {
    background-position: -160px -400px; }
  .relux .icon__checkmark:hover {
    background-position: -160px -440px; }
  .relux .icon__checkmark.icon--nohover:hover {
    background-position: -160px -400px; }
  .relux .icon__search {
    background-position: -200px -400px; }
  .relux .icon__search:hover {
    background-position: -200px -440px; }
  .relux .icon__search.icon--nohover:hover {
    background-position: -200px -400px; }
  .relux .icon__delete {
    background-position: -240px -400px; }
  .relux .icon__delete:hover {
    background-position: -240px -440px; }
  .relux .icon__delete.icon--nohover:hover {
    background-position: -240px -400px; }
  .relux .icon__link {
    background-position: -280px -400px; }
  .relux .icon__link:hover {
    background-position: -280px -440px; }
  .relux .icon__link.icon--nohover:hover {
    background-position: -280px -400px; }
  .relux .icon__edit {
    background-position: -320px -400px; }
  .relux .icon__edit:hover {
    background-position: -320px -440px; }
  .relux .icon__edit.icon--nohover:hover {
    background-position: -320px -400px; }
  .relux .icon__trashcan {
    background-position: -360px -400px; }
  .relux .icon__trashcan:hover {
    background-position: -360px -440px; }
  .relux .icon__trashcan.icon--nohover:hover {
    background-position: -360px -400px; }
  .relux .icon__datasheet {
    background-position: -400px -400px;
    width: 16px; }
  .relux .icon__datasheet:hover {
    background-position: -400px -440px; }
  .relux .icon__datasheet.icon--nohover:hover {
    background-position: -400px -400px; }
  .relux .icon__printer {
    background-position: -440px -400px; }
  .relux .icon__printer:hover {
    background-position: -440px -440px; }
  .relux .icon__printer.icon--nohover:hover {
    background-position: -440px -400px; }
  .relux .icon__ldt {
    background-position: -480px -400px; }
  .relux .icon__ldt:hover {
    background-position: -480px -440px; }
  .relux .icon__ldt.icon--nohover:hover {
    background-position: -480px -400px; }
  .relux .icon__rolf {
    background-position: -520px -400px; }
  .relux .icon__rolf:hover {
    background-position: -520px -440px; }
  .relux .icon__rolf.icon--nohover:hover {
    background-position: -520px -400px; }
  .relux .icon__comparison {
    background-position: -560px -400px; }
  .relux .icon__comparison:hover {
    background-position: -560px -440px; }
  .relux .icon__comparison.icon--nohover:hover {
    background-position: -560px -400px; }
  .relux .icon__plus {
    background-position: -600px -400px; }
  .relux .icon__plus:hover {
    background-position: -600px -440px; }
  .relux .icon__plus.icon--nohover:hover {
    background-position: -600px -400px; }
  .relux .tab-navigation {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
    border-bottom: 1px solid #fff;
    font-weight: bold;
    height: 40px;
    padding-bottom: 1px;
    width: 100%; }
  .relux .tab-navigation li {
    background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
    border: 1px solid;
    border-right-color: #ccc;
    border-bottom: none;
    border-left-color: #fff;
    border-top: none;
    height: 40px;
    line-height: 40px;
    padding: 0 11px;
    position: relative;
    float: left; }
  .relux .tab-navigation li.active {
    background: #efefef;
    border-bottom: none;
    border-right: none; }
  .relux .tab-navigation li:first-child {
    border-left: none;
    padding-left: 11px !important; }
  .relux .account-administration .fullwidth-content-container {
    padding-top: 40px; }
  .relux .fullwidth-content-container {
    margin: 32px 40px 40px; }
  .relux .fullwidth-content-container select.big,
  .relux .fullwidth-content-container input[type="text"].big,
  .relux .fullwidth-content-container input[type="password"].big,
  .relux .fullwidth-content-container input[type="email"].big,
  .relux .fullwidth-content-container input[type="number"].big,
  .relux .fullwidth-content-containerinput[type="search"].big,
  .relux .fullwidth-content-containertextarea.big {
    width: 35%;
    margin-bottom: 20px; }
  .relux table.account-administration td, .relux table.account-administration th {
    padding: 7px 7px 7px 0; }
  @media print {
    .relux header {
      display: none; }
    .relux .datasheet .hidden {
      display: block; }
    .relux .datasheet .grid-col-8 {
      display: block; }
    .relux .datasheet .js-tab-content {
      display: block; }
    .relux .datasheet .grid-col-8 .js-tab-content.hidden {
      display: block; }
    .relux .datasheet h1 {
      font-size: 29px;
      line-height: 42px; }
    .relux .datasheet .headerGrid .grid-col-7 {
      width: 58%;
      margin: 0;
      padding: 0;
      float: left; }
    .relux .datasheet .headerGrid .grid-col-3 {
      width: 42%;
      margin: 0;
      padding: 0;
      float: left; }
    .relux .datasheet .headerGrid .grid-col-2 {
      display: none; }
    .relux .datasheet .table-grid.logos {
      width: 5.5cm;
      margin-left: auto;
      margin-right: auto; }
      .relux .datasheet .table-grid.logos .datasheet-image-screen {
        visibility: visible;
        width: 5cm;
        height: 5cm; }
    .relux .datasheet .datasheet-technical-details.hidden {
      display: inline-block; } }
  .relux .component.releasenotes ul li, .relux .component.releasenotes ul ol {
    list-style: disc;
    font-size: 15px;
    line-height: 22px; }
  .relux #newsimage {
    width: calc(100% - 200px);
    margin: 0 0 20px 0;
    max-height: 220px; }
    .relux #newsimage img {
      float: right;
      max-height: 220px; }
  @media screen and (min-width: 240px) and (max-width: 1024px) {
    .relux .manufacturer-description-logo-only,
    .relux #newsimage {
      float: none;
      width: 100%;
      max-width: inherit;
      max-height: inherit; }
      .relux .manufacturer-description-logo-only img,
      .relux #newsimage img {
        float: none;
        max-width: 100%; } }
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    .relux #newsimage {
      margin: 0 0 20px 0;
      max-height: 220px; }
      .relux #newsimage img {
        width: calc(80px + ((3/3) * (100% - (((100% - 60px) - 200px) / 4))));
        float: right;
        max-height: 220px; } }
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    .relux #newsimage {
      margin: 0 0 20px 0;
      max-height: 220px; }
      .relux #newsimage img {
        width: calc(71px + ((3/4) * (100% - (((100% - 80px) - 200px) / 5))));
        float: right;
        max-height: 220px; } }
  @media screen and (min-width: 1600px) and (max-width: 1920px) {
    .relux #newsimage {
      margin: 0 0 20px 0;
      max-height: 220px; }
      .relux #newsimage img {
        width: calc(86px + ((4/5) * (100% - (((100% - 100px) - 200px) / 6))));
        float: right;
        max-height: 220px; } }
  @media screen and (min-width: 1920px) and (max-width: 2560px) {
    .relux #newsimage {
      margin: 0 0 20px 0;
      max-height: 220px; }
      .relux #newsimage img {
        width: calc(77px + ((4/6) * (100% - (((100% - 120px) - 200px) / 7))));
        float: right;
        max-height: 220px; } }
  @media screen and (min-width: 2560px) {
    .relux #newsimage {
      margin: 0 0 20px 0;
      max-height: 220px; }
      .relux #newsimage img {
        width: calc(60px + ((4/8) * (100% - (((100% - 160px) - 200px) / 9))));
        float: right;
        max-height: 220px; } }
  .relux.user_products .main-header.fixed.narrow nav.navbar.navbar-default ul {
    margin-top: 0px;
    transition: all 0.5s ease-in-out; }
  .relux .main-header.fixed.narrow nav.navbar.navbar-default ul {
    margin-top: -15px;
    transition: all 0.5s ease-in-out; }
  .relux nav.navbar-default {
    background-color: black;
    border: none;
    border-color: black; }
    .relux nav.navbar-default a.mobile_logo {
      display: none; }
      @media screen and (max-width: 800px) {
        .relux nav.navbar-default a.mobile_logo {
          display: inline-block;
          background: transparent url(../assets/logo-white.svg) 0 0 no-repeat;
          text-indent: -9999px;
          margin-left: 20px;
          position: relative;
          top: 20px;
          width: 124px;
          height: auto; } }
    .relux nav.navbar-default #relux_nav_logo {
      background: transparent url(../assets/logo-white.svg) 0 0 no-repeat;
      margin-top: 28px;
      width: 142.85714px;
      height: 24px;
      margin-right: 38px;
      text-indent: -9999px; }
      @media screen and (max-width: 800px) {
        .relux nav.navbar-default #relux_nav_logo {
          display: none; } }
    .relux nav.navbar-default .navbar-nav {
      margin-bottom: 0; }
      .relux nav.navbar-default .navbar-nav li.dropdown.open a.dropdown-toggle {
        background-color: black;
        color: #e2007a; }
      .relux nav.navbar-default .navbar-nav li a {
        color: #ffffff;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 30px;
        padding-bottom: 9px;
        letter-spacing: 1px; }
        .relux nav.navbar-default .navbar-nav li a.button.white {
          padding-top: 0px;
          margin-top: 21px; }
        .relux nav.navbar-default .navbar-nav li a.active {
          color: #e2007a; }
      .relux nav.navbar-default .navbar-nav li .dropdown-menu li a {
        color: black;
        padding-top: 7px;
        padding-bottom: 7px; }
        @media screen and (max-width: 800px) {
          .relux nav.navbar-default .navbar-nav li .dropdown-menu li a {
            color: white; } }
        .relux nav.navbar-default .navbar-nav li .dropdown-menu li a.active {
          color: #e2007a; }
  .relux .row.circlerow {
    margin-bottom: 2em; }
    .relux .row.circlerow .circle_image {
      width: 75%;
      margin: 12px auto 0 auto; }
    .relux .row.circlerow .circle_image3 {
      width: 65%;
      margin: 12px auto 0 auto; }
    .relux .row.circlerow .circle_down_div {
      margin-top: 2em; }
      .relux .row.circlerow .circle_down_div h3, .relux .row.circlerow .circle_down_div p {
        margin-bottom: 14px; }
      .relux .row.circlerow .circle_down_div .circle_button {
        text-align: center; }
      .relux .row.circlerow .circle_down_div .homepage-slider-button {
        margin-top: 0; }
